import { ApiContext } from '../../types'
import getCurrentBearerToken from '../authentication/getCurrentBearerToken'
import { SaleReport } from '@/api_client/types/report'

export default async function getProductReport(
  { client, config }: ApiContext,
  start_date,
  end_date,
): Promise<any> {
  const bearerToken = await getCurrentBearerToken({ client, config })

  const response = await client.report.products(
    { bearerToken },
    { start_date, end_date },
  )
  if (response.isSuccess()) {
    // console.log(response.success())
    // const data = response.success().data
    return response.success()
  } else {
    return null
  }
}
