import { deserializeUser } from '@/api_client/api/serializers/user'
import { Attendance } from '@/api_client/types/attendance'
import { AttendanceAttr } from '@/api_client/interfaces/Attendance'

const findAttachment = (attachments: any[], type: string, id) => {
  // console.log('findAttachment')
  // console.log(attachments)
  // console.log(type)
  // console.log(id)
  return attachments?.find(
    (e) => e.type === type && e.id.toString() === id.toString(),
  )
}

export const deserializeAttendance = (
  attr: AttendanceAttr,
  include,
): Attendance => ({
  id: attr.id,
  user_id: attr.attributes.user_id,
  attend_date: attr.attributes.attend_date,
  attend_start: attr.attributes.attend_start,
  attend_end: attr.attributes.attend_end,
  attend_check_in: attr.attributes.attend_check_in,
  attend_check_out: attr.attributes.attend_check_out,
  attend_working_minutes: attr.attributes.attend_working_minutes,
  worked_minutes: attr.attributes.worked_minutes,
  counted_worked_minutes: attr.attributes.counted_worked_minutes,
  ot_minutes: attr.attributes.ot_minutes,
  adjustment_minutes: attr.attributes.adjustment_minutes,
  late_minutes: attr.attributes.late_minutes,
  is_count_as_ot: attr.attributes.is_count_as_ot,
  notes: attr.attributes.notes,
  created_at: attr.attributes.created_at,
  updated_at: attr.attributes.updated_at,
  user: deserializeUser(
    findAttachment(include, 'user', attr.relationships['user']['data']['id']),
    include,
  ),
})
