import { ApiContext } from '../../types'
// import getCurrentBearerToken from '../authentication/getCurrentBearerToken'
import getCurrentBearerToken from '@/api_client/api/authentication/getCurrentBearerToken'
// import getCurrentBearerToken from "@/api_client/api/authentication/getCurrentBearerToken";

export default async function addPayment(
  { client, config }: ApiContext,
  orderId,
  {
    paymentMethodId,
    amount,
    source_type = null,
    source_id = null,
    state = null,
  },
): Promise<void> {
  const bearerToken = await getCurrentBearerToken({
    client,
    config,
  })

  // const serializedAddress = serializeAddress(params)
  const result = await client.payment.createPayment(
    { bearerToken },
    {
      payment: {
        order_id: orderId,
        payment_method_id: paymentMethodId,
        amount: amount,
        source_type: source_type,
        source_id: source_id,
        state: state,
      },
    },
  )

  if (result.isFail()) {
    console.log('result.fail()')
    console.log(result.fail())
    throw result.fail()
  }
}
