
import { watch } from 'vue'
import { useStore } from 'vuex'
import { AuthHelper } from '@/api_client'
// import axios from 'axios'

export default {
  setup() {
    const store = useStore()

    watch(store.state, () => {
      store.state.theme === 'dark'
        ? document.body.classList.add('dark-theme')
        : document.body.classList.remove('dark-theme')
    })

    store.state.theme === 'dark'
      ? document.body.classList.add('dark-theme')
      : document.body.classList.remove('dark-theme')
  },
  data() {
    return { isAllowedIPAccess: true }
  },
  mounted() {
    const auth = AuthHelper.getOAuthToken()
    // window.location.pathname
    // console.log(window.location)
    if (
      !window.location.hash.startsWith('#/login') &&
      !window.location.hash.startsWith('login#/') &&
      (auth.access_token == null || !AuthHelper.isRoleExist())
    ) {
      AuthHelper.removeOAuthToken()
      window.location.href = '/#/login'
    }

    // axios.get('http://ipinfo.io/json').then(({ data }) => {
    //   console.log(data)
    // })
    // const allowedIPs = ['118.69.191.991']
    // fetch('https://api.ipify.org?format=json')
    //   .then((x) => x.json())
    //   .then(({ ip }) => {
    //     console.log(ip)
    //     this.isAllowedIPAccess = allowedIPs.includes(ip)
    //     console.log('this.isAllowedIPAccess')
    //     console.log(this.isAllowedIPAccess)
    //     if (!this.isAllowedIPAccess) {
    //       AuthHelper.removeOAuthToken()
    //       window.location.href = '/#/restricted'
    //     }
    //   })
  },
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        // console.log('this.isAllowedIPAccess')
        // console.log(this.isAllowedIPAccess)
        // if (!this.isAllowedIPAccess) {
        //   window.location.href = '/#/restricted'
        // }
        // console.log('asd')
        // console.log(to)
        document.title = (to.name || 'Home') + ' | Second Corner Backend'
        // document
        // document = (to.name || 'Home') + ' | Second Corner Backend'
      },
    },
  },
}
