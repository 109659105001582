import { Address, ApiContext } from '../../types'
// import getCurrentBearerToken from '../authentication/getCurrentBearerToken'
import { serializeAddress } from '../serializers/address'
import getCurrentBearerToken from '@/api_client/api/authentication/getCurrentBearerToken'
// import getCurrentBearerToken from "@/api_client/api/authentication/getCurrentBearerToken";

export default async function addAddress(
  { client, config }: ApiContext,
  params: Address,
): Promise<void> {
  const bearerToken = await getCurrentBearerToken({
    client,
    config,
  })

  const serializedAddress = serializeAddress(params)
  const result = await client.account.createAddress(
    { bearerToken },
    { address: serializedAddress },
  )

  if (result.isFail()) {
    console.log('result.fail()')
    console.log(result.fail())
    throw result.fail()
  }
}
