import { ApiContext } from '../../types'
import getCurrentBearerOrCartToken from '../authentication/getCurrentBearerOrCartToken'
import { deserializePayoutMethod } from '@/api_client/api/serializers/user'

export default async function getPayoutMethods(
  { client, config }: ApiContext,
  user_id,
) {
  const token = await getCurrentBearerOrCartToken({ client, config })
  const result = await client.users.getPayoutMethods(token, user_id)

  if (result.isSuccess()) {
    const payload = result.success()
    return payload.data.map(deserializePayoutMethod)
  } else {
    throw result.fail()
  }
}
