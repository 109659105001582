import { IOAuthToken } from '@spree/storefront-api-v2-sdk/types/interfaces/Token'
import { deserializeUser } from '@/api_client/api/serializers/user'
import POSHelper from '@/helpers/POSHelper'

class AuthHelper {
  public static ROLE_LIST = [
    'sadmin',
    'admin',
    'operation assistant',
    'manager',
    'supervisor',
    'consignment-assistant',
    'salesperson',
  ]

  public static allowedIPs = ['118.69.191.99']
  public static allowedUserIds = ['1', '51', '166', '9090']

  public static PAGE_AUTHORITIES = [
    { page: 'POS', roles: ['operation assistant', 'manager', 'supervisor'] },
    {
      // router index name
      page: 'Payouts',
      roles: ['manager', 'supervisor'],
    },
    {
      page: 'Customers',
      roles: ['operation assistant', 'manager', 'supervisor'],
    },
    {
      page: 'Customer Info',
      roles: ['operation assistant', 'manager', 'supervisor'],
    },
    {
      page: 'Customer Orders',
      roles: ['operation assistant', 'manager', 'supervisor'],
    },
    {
      page: 'Customer Consignments',
      roles: ['operation assistant', 'manager', 'supervisor'],
    },
    {
      page: 'Customer Credits',
      roles: ['operation assistant', 'manager', 'supervisor'],
    },
    {
      page: 'Customer Payouts',
      roles: ['operation assistant', 'manager', 'supervisor'],
    },
    {
      page: 'Customer Reset Passwrod',
      roles: ['operation assistant', 'manager', 'supervisor'],
    },
    {
      page: 'User Roles',
      roles: ['operation assistant', 'manager', 'supervisor'],
    },
    {
      page: 'Customer Payout Methods',
      roles: ['operation assistant', 'manager', 'supervisor'],
    },
    {
      page: 'Management',
      roles: ['operation assistant', 'manager', 'supervisor'],
    },
    {
      page: 'Employees',
      roles: ['sadmin'],
    },
    {
      page: 'Home sections',
      roles: ['sadmin'],
    },
    {
      page: 'Reports',
      roles: ['supervisor'],
    },
    {
      page: 'Consignments Report',
      roles: ['sadmin'],
    },
    {
      page: 'Payouts Report',
      roles: ['sadmin'],
    },
    {
      page: 'Products Report',
      roles: ['sadmin'],
    },
    {
      page: 'HR',
      roles: ['supervisor'],
    },
    {
      page: 'Shift Table',
      roles: ['supervisor'],
    },
    {
      page: 'Shift Management',
      roles: ['supervisor'],
    },
  ]

  public static getUserId(): string {
    return localStorage.getItem('user_id')
  }

  public static isHuy(): boolean {
    return localStorage.getItem('user_id') == '166'
  }

  public static getUserName(): string {
    return localStorage.getItem('name')
  }

  public static getUserEmail(): string {
    return localStorage.getItem('username')
  }

  public static isRoleExist(): boolean {
    // console.log(localStorage.getItem('roles'))
    return localStorage.getItem('roles')?.length > 0
  }

  public static guard(page) {
    // console.log(page)
    // console.log(localStorage.getItem('roles'))
    if (AuthHelper.isAuthorized(page)) {
      return true
    }
    location.replace('/#/')
    return false
  }

  public static isAuthorized(page) {
    const currentRoles = AuthHelper.getRoles()
    if (page == 'Reports' && currentRoles.includes('operation assistant')) {
      return false
    }

    const authorizedRoles = AuthHelper.PAGE_AUTHORITIES.find(
      (p) => p.page === page,
    )
    if (authorizedRoles == null) {
      return true
    }

    return AuthHelper.isRoleIn(authorizedRoles.roles)
  }

  // TODO: fix many roles
  public static getRole(): string {
    return (localStorage.getItem('roles') || '')
      .split(',')
      .find((r) => r != 'admin')
  }

  public static getRoles(): string[] {
    return (localStorage.getItem('roles') || '').split(',')
  }

  public static isRoleIn(role: string | string[]): boolean {
    // console.log('isRoleIn')
    if (role == null) {
      return true
    }
    const currentRoles = AuthHelper.getRoles()
    if (currentRoles == null || currentRoles.length === 0) {
      return false
    }

    if (currentRoles.includes('sadmin')) {
      return true
    }
    if (Array.isArray(role)) {
      for (const r of role) {
        if (currentRoles.includes(r)) {
          return true
        }
      }
    } else {
      return currentRoles.includes(role)
    }
  }

  public static isSupervisor(): boolean {
    return AuthHelper.isRoleIn([
      'sadmin',
      'supervisor',
      'operation assistant',
      'manager',
    ])
  }

  public static setRoles(roles) {
    localStorage.setItem(
      'roles',
      roles
        .map((r) => r.name)
        .filter((r) => r != 'admin')
        .join(','),
    )
  }

  public static async getUserInfo(client, bearerToken) {
    const response = await client.users.list(
      { bearerToken },
      {
        filter: { email_eq: localStorage.getItem('username') },
        include: 'spree_roles',
      },
    )
    if (response.isSuccess()) {
      const data = response.success()
      const theUser = deserializeUser(data.data[0], data.included)
      localStorage.setItem('name', theUser.name)
      localStorage.setItem('user_id', theUser.id.toString())
      localStorage.setItem(
        'roles',
        theUser.roles
          .map((r) => r.name)
          .filter((r) => r != 'admin')
          .join(','),
      )
    } else {
      localStorage.removeItem('name')
      localStorage.removeItem('user_id')
      localStorage.removeItem('roles')
    }
  }

  public static getOAuthToken(): IOAuthToken {
    return {
      access_token: localStorage.getItem('access_token'),
      refresh_token: localStorage.getItem('refresh_token'),
      created_at: +localStorage.getItem('created_at'),
      expires_in: +localStorage.getItem('expires_in'),
      token_type: 'Bearer',
    }
  }

  public static changeOAuthToken(newValue: IOAuthToken, username?: string) {
    if (username) {
      localStorage.setItem('username', username)
    }

    localStorage.setItem('access_token', newValue.access_token)
    localStorage.setItem('refresh_token', newValue.refresh_token)
    localStorage.setItem('created_at', newValue.created_at.toString())
    localStorage.setItem('expires_in', newValue.expires_in.toString())
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public static removeOAuthToken() {
    localStorage.removeItem('name')
    localStorage.removeItem('user_id')
    localStorage.removeItem('roles')
    localStorage.removeItem('username')
    localStorage.removeItem('access_token')
    localStorage.removeItem('refresh_token')
    localStorage.removeItem('created_at')
    localStorage.removeItem('expires_in')
    POSHelper.removeAllSavedOrderIds()
  }
}

export default AuthHelper
