import { ApiContext } from '../../types'
import getCurrentBearerToken from '../authentication/getCurrentBearerToken'

export default async function updatePayoutState(
  { client, config }: ApiContext,
  payout_id,
  state,
): Promise<any> {
  try {
    const token = await getCurrentBearerToken({
      client,
      config,
    })

    // if (params.filter) {
    //   params.filter.number_cont = params.filter.number
    //   params.filter.state_cont = params.filter.state
    // }
    const response = await client.payouts.updateState(
      { bearerToken: token },
      payout_id,
      { payout: { state: state } },
    )
    if (response.isSuccess()) {
      // const payload = response.success()
      // console.log(payload)
      // const data = payload.data.map((order) =>
      //   deserializePayout(order, payload.included, config),
      // )
      // return { ...payload, data }
      return true
    } else {
      throw response.fail()
    }
  } catch (e) {
    console.error(e)
    throw e
  }
}
