import { ApiContext } from '../../types'
import getCurrentBearerToken from '@/api_client/api/authentication/getCurrentBearerToken'
import { deserializeAttendance } from '@/api_client/api/serializers/attendance'

// old
export default async function addAttendance(
  { client, config }: ApiContext,
  data,
  params = {},
): Promise<any> {
  try {
    const token = await getCurrentBearerToken({
      client,
      config,
    })

    const result = await client.attendances.create(
      { bearerToken: token },
      { ...params, include: 'user' },
      data,
    )

    if (result.isSuccess()) {
      const payload = result.success()
      return deserializeAttendance(payload.data, payload.included)
    } else {
      throw result.fail()
    }
  } catch (e) {
    console.error(e)
    throw e
  }
}
