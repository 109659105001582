import Http from '../Http'
import { IProductResult, IProductsResult } from '../interfaces/Product'
import { IQuery } from '../interfaces/Query'
import { Routes } from '../routes'
import { IToken } from '@/api_client/interfaces/Token'

export default class Products extends Http {
  public async list(
    token: IToken,
    params: IQuery = {},
  ): Promise<IProductsResult> {
    return (await this.spreeResponse(
      'get',
      Routes.productsPath(),
      token,
      params,
    )) as IProductsResult
  }

  public async show(
    token: IToken,
    id: string,
    params: IQuery = {},
  ): Promise<IProductResult> {
    return (await this.spreeResponse(
      'get',
      Routes.productPath(id),
      token,
      params,
    )) as IProductResult
  }

  public async addProductStock(
    token: IToken,
    id: string,
  ): Promise<IProductResult> {
    return (await this.spreeResponse(
      'get',
      Routes.addProductStock(id),
      token,
      {},
    )) as IProductResult
  }

  public async dropProductStock(
    token: IToken,
    id: string,
  ): Promise<IProductResult> {
    return (await this.spreeResponse(
      'get',
      Routes.dropProductStock(id),
      token,
      {},
    )) as IProductResult
  }

  public async update(
    token: IToken,
    id: string,
    params: any,
  ): Promise<IProductResult> {
    return (await this.spreeResponse(
      'patch',
      Routes.productPath(id),
      token,
      {},
      params,
    )) as IProductResult
  }

  public async create(
    token: IToken,
    id: string,
    params: any,
  ): Promise<IProductResult> {
    return (await this.spreeResponse(
      'post',
      Routes.productsPath(),
      token,
      {},
      params,
    )) as IProductResult
  }

  public async delete(token: IToken, id: string): Promise<IProductResult> {
    return (await this.spreeResponse(
      'delete',
      Routes.productPath(id),
      token,
      {},
    )) as IProductResult
  }

  public async connect_consignment_line_item(
    token: IToken,
    id: string,
  ): Promise<IProductResult> {
    return (await this.spreeResponse(
      'patch',
      Routes.productConnectConsignmentLineItemPath(id),
      token,
      {},
    )) as IProductResult
  }

  public async addProductDiscount(token: IToken, body: any): Promise<any> {
    return await this.spreeResponse(
      'post',
      Routes.productDiscountsPath(),
      token,
      {},
      body,
    )
  }

  public async discountProductDirect(
    token: IToken,
    product_id: any,
    discount,
  ): Promise<any> {
    return await this.spreeResponse(
      'patch',
      Routes.productDiscountDirectPath(product_id),
      token,
      { discount: discount },
    )
  }

  public async deleteProductDiscount(token: IToken, id: any): Promise<any> {
    return await this.spreeResponse(
      'delete',
      Routes.productDiscountPath(id),
      token,
    )
  }

  // public async addLogEntry(
  //   token: IToken,
  //   id: any,
  //   data,
  //   params = null,
  // ): Promise<any> {
  //   return await this.spreeResponse(
  //     'post',
  //     Routes.productLogEntryPath(id),
  //     token,
  //     params,
  //     data,
  //   )
  // }
}
