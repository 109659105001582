import { ApiContext } from '../../types'
import getCurrentBearerToken from '@/api_client/api/authentication/getCurrentBearerToken'
import { deserializePayoutItem } from '@/api_client/api/serializers/payout'
import { PayoutItem } from '@/api_client/types/payout'

// old
export default async function addPayoutItem(
  { client, config }: ApiContext,
  payoutId,
  cLineItemId,
): Promise<PayoutItem> {
  try {
    const token = await getCurrentBearerToken({
      client,
      config,
    })

    const result = await client.payouts.addPayoutItem(
      { bearerToken: token },
      payoutId,
      {
        consignment_line_item_id: cLineItemId,
        include: 'consignment_line_item,consignment_line_item.variant',
      },
    )

    if (result.isSuccess()) {
      // maybe because the item is rejected in consignment
      const payload = result.success()
      return deserializePayoutItem(payload.data, payload.included, [config], 0)
    } else {
      throw result.fail()
    }
  } catch (e) {
    console.error(e)
    throw e
  }
}
