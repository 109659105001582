import { ApiContext } from '../../types'
import getCurrentBearerToken from '../authentication/getCurrentBearerToken'
import { IQuery } from '@/api_client/interfaces/Query'
import { deserializeUser } from '@/api_client/api/serializers/user'

export default async function getUsers(
  { client, config }: ApiContext,
  params: IQuery = {},
  extraInclude = '',
) {
  const bearerToken = await getCurrentBearerToken({ client, config })
  params.per_page = params.per_page || 10
  params.sort = params.sort || '-id'
  params.include = 'ship_address,' + extraInclude

  const response = await client.users.list({ bearerToken }, params)
  if (response.isSuccess()) {
    const success = response.success()
    // console.log(success)
    const data = success.data
    const included = success.included
    // result.data = result.data.map(deserializeUser)
    return {
      data: data.map((d) => deserializeUser(d, included)),
      meta: success.meta,
    }
  } else {
    console.log('getUsers: fail')
    throw response.fail()
  }
}
