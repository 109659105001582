import { ApiContext } from '../../types'
import getCurrentBearerToken from '@/api_client/api/authentication/getCurrentBearerToken'
import { deserializePayoutMethod } from '@/api_client/api/serializers/user'
import { PayoutMethod } from '@/api_client/types/user'

// old
export default async function savePayoutMethod(
  { client, config }: ApiContext,
  user_id,
  payout_method,
  payout_method_id = null,
): Promise<PayoutMethod> {
  try {
    const token = await getCurrentBearerToken({
      client,
      config,
    })

    let result

    if (payout_method_id == null) {
      result = await client.users.createPayoutMethods(
        { bearerToken: token },
        user_id,
        { payout_method },
      )
    } else {
      result = await client.users.updatePayoutMethods(
        { bearerToken: token },
        user_id,
        payout_method_id,
        { payout_method },
      )
    }

    if (result.isSuccess()) {
      const payload = result.success()
      return deserializePayoutMethod(payload.data)
    } else {
      throw result.fail()
    }
  } catch (e) {
    console.error(e)
    throw e
  }
}
