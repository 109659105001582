import { ApiContext } from '../../types'
import getCurrentBearerToken from '@/api_client/api/authentication/getCurrentBearerToken'

export default async function removeCoupon(
  { client, config }: ApiContext,
  orderId,
  promotion_id,
): Promise<boolean> {
  try {
    const token = await getCurrentBearerToken({
      client,
      config,
    })

    const result = await client.cart.removeCouponCode(
      { bearerToken: token },
      orderId,
      promotion_id,
    )

    if (result.isSuccess()) {
      return true
      // const payload = result.success()
      // console.log(payload)
      // const cart = deserializeCart(payload.data, payload.included, config)
      // return cart
    } else {
      console.error(result.fail())
      throw result.fail()
    }
  } catch (e) {
    console.error(e)
    throw e
  }
}
