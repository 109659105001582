import { CouponCode as CouponCodeAttr } from '@spree/storefront-api-v2-sdk/types/interfaces/endpoints/CartClass'
import { CouponCode } from '../../types'
import { getDatetimeString } from '@/api_client/api/serializers/getters'
import { CmsPage } from '@/api_client/types/CmsPage'
import { CmsPageAttr } from '@/api_client/interfaces/CmsPage'
import { deserializeImages } from '@/api_client/api/serializers/product'

const findAttachment = (attachments: any[], id: string, type: string) => {
  if (id == null) {
    return null
  }
  return attachments?.find(
    (e) => e.id.toString() === id.toString() && e.type === type,
  )
}

export const deserializeCMSPage = (
  apiCart: CmsPageAttr,
  included: any[],
  config: any,
): CmsPage => {
  return {
    id: parseInt(apiCart.id, 10),
    title: apiCart.attributes.title,
    slug: apiCart.attributes.slug,
    type: apiCart.attributes.type,
    meta_title: apiCart.attributes.meta_title,
    meta_description: apiCart.attributes.meta_description,
    content: apiCart.attributes.content,
    visible: apiCart.attributes.visible,
    created_at: getDatetimeString(apiCart.attributes.created_at),
    updated_at: getDatetimeString(apiCart.attributes.updated_at),
    deleted_at: getDatetimeString(apiCart.attributes.deleted_at),
    images: deserializeImages(included, [apiCart]),
  }
}

export const serializeCouponCode = ({
  couponCode,
}: CouponCode): CouponCodeAttr => ({
  coupon_code: couponCode,
})
