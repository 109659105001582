import { ApiContext } from '../../types'
import getCurrentBearerOrCartToken from '../authentication/getCurrentBearerOrCartToken'
import { deserializeStockLocation } from '@/api_client/api/serializers/stock_location'
import { StockLocation } from '@/api_client/types/stock_location'

export default async function getStockLocations({
  client,
  config,
}: ApiContext): Promise<StockLocation[]> {
  const token = await getCurrentBearerOrCartToken({ client, config })
  const result = await client.stockLocation.show(token, {
    include: 'stock_location_areas',
    filter: {},
  })
  // console.log('getStockLocations')
  if (result.isSuccess()) {
    const payload = result.success()
    const included = payload.included
    const r = payload.data.map((d) => deserializeStockLocation(d, included))
    return r
  } else {
    throw result.fail()
  }
}
