// import { Logger } from '@vue-storefront/core'
import { ApiContext } from '../../types'
import getCurrentBearerToken from '../authentication/getCurrentBearerToken'

export default async function cancelOrder(
  { client, config }: ApiContext,
  order_id,
): Promise<any> {
  try {
    const token = await getCurrentBearerToken({ client, config })
    const response = await client.cart.cancelOrder(
      { bearerToken: token },
      order_id,
    )

    if (response.isSuccess()) {
      const payload = response.success()
      console.log(payload)
      // const data = payload.data.map((order) =>
      //   deserializeCart(order, payload.included, config),
      // )
      // payload.data = deserializedData
      // return { ...payload, data }
      return true
    } else {
      console.log(response.fail())
      // throw response.fail()
      return false
    }
  } catch (e) {
    console.error(e)
    throw e
  }
}
