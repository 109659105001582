import AttendanceRecords from '@/api_client/endpoints/AttendanceRecords'

export const storefrontPath = `api/v2/storefront`
export const platformPath = `api/v2/platform`

export const Routes = {
  // productLogEntryPath: (id: string) => `${platformPath}/products/${id}/log`,
  productDiscountsPath: () => `${platformPath}/product_discounts`,
  productDiscountPath: (id: string) =>
    `${platformPath}/product_discounts/${id}`,
  productDiscountDirectPath: (id: string) =>
    `${platformPath}/products/${id}/discount`,
  productsPath: () => `${platformPath}/products`,
  productPath: (id: string) => `${platformPath}/products/${id}`,
  productConnectConsignmentLineItemPath: (id: string) =>
    `${platformPath}/products/${id}/connect_consignment_line_item`,
  addProductStock: (id: string) => `${platformPath}/add_product_stock/${id}`,
  dropProductStock: (id: string) => `${platformPath}/drop_product_stock/${id}`,
  taxonsPath: () => `${platformPath}/taxons`,
  taxonomiesPath: () => `${platformPath}/taxonomies`,
  taxonPath: (id: string) => `${platformPath}/taxons/${id}`,
  countriesPath: () => `${storefrontPath}/countries`,
  countryPath: (iso: string) => `${storefrontPath}/countries/${iso}`,
  cartPath: () => `${storefrontPath}/cart`,
  cartAddItemPath: () => `${storefrontPath}/cart/add_item`,
  cartRemoveItemPath: (id: string) =>
    `${storefrontPath}/cart/remove_line_item/${id}`,
  cartEmptyPath: () => `${storefrontPath}/cart/empty`,
  cartSetItemQuantity: () => `${storefrontPath}/cart/set_quantity`,
  cartApplyCodePath: (id) => `${platformPath}/orders/${id}/apply_coupon_code`,
  removeCouponPath: (id, promotion_id: string) =>
    `${platformPath}/orders/${id}/remove_coupon/${promotion_id}`,
  cartEstimateShippingMethodsPath: () =>
    `${storefrontPath}/cart/estimate_shipping_rates`,
  checkoutPath: () => `${storefrontPath}/checkout`,
  checkoutNextPath: () => `${storefrontPath}/checkout/next`,
  checkoutAdvancePath: () => `${storefrontPath}/checkout/advance`,
  checkoutCompletePath: () => `${storefrontPath}/checkout/complete`,
  checkoutAddStoreCreditsPath: () =>
    `${storefrontPath}/checkout/add_store_credit`,
  checkoutRemoveStoreCreditsPath: () =>
    `${storefrontPath}/checkout/remove_store_credit`,
  checkoutPaymentMethodsPath: () => `${platformPath}/payment_methods`,
  checkoutPromotionsPath: () => `${platformPath}/promotions`,
  checkoutFreeShipPath: () => `${platformPath}/free_ship`,
  checkoutShippingMethodsPath: () => `${platformPath}/shipping_methods`,
  oauthTokenPath: () => `spree_oauth/token`,
  accountPath: () => `${platformPath}/account`,
  accountAddressPath: (id: string) =>
    `${storefrontPath}/account/addresses/${id}`,
  accountAddressesPath: () => `${storefrontPath}/account/addresses`,
  accountConfirmPath: (confirmationToken: string) =>
    `${storefrontPath}/account_confirmations/${confirmationToken}`,
  accountCreditCardsPath: () => `${storefrontPath}/account/credit_cards`,
  accountDefaultCreditCardPath: () =>
    `${storefrontPath}/account/credit_cards/default`,
  accountCompletedOrdersPath: () => `${storefrontPath}/account/orders`,
  accountCompletedOrderPath: (orderNumber: string) =>
    `${storefrontPath}/account/orders/${orderNumber}`,
  orderStatusPath: (orderNumber: string) =>
    `${storefrontPath}/order_status/${orderNumber}`,
  usersPath: () => `${platformPath}/users`,
  ordersPath: () => `${platformPath}/orders`,
  ordersMetaPath: () => `${platformPath}/order_meta_data`,
  returnOrderItemPath: (lineItemId) =>
    `${platformPath}/line_items/${lineItemId}/return_item`,
  shipOrderItemPath: (lineItemId) =>
    `${platformPath}/line_items/${lineItemId}/ship_item`,
  orderPath: (orderId) => `${platformPath}/orders/${orderId}`,
  completeOrderPath: (orderId) => `${platformPath}/orders/${orderId}/complete`,
  returnOrderPath: (orderId) => `${platformPath}/orders/${orderId}/return`,
  cancelOrderPath: (orderId) => `${platformPath}/orders/${orderId}/cancel`,
  orderShippingMethodPath: (orderId) =>
    `${platformPath}/orders/${orderId}/set_shipment`,
  orderAddItemPath: (orderId) => `${platformPath}/orders/${orderId}/add_item`,
  orderAddItemPath1: (orderId) =>
    `${platformPath}/orders/${orderId}/line_items`,
  orderRemoveItemPath: (orderId, lineItemId) =>
    `${platformPath}/orders/${orderId}/remove_line_item/${lineItemId}`,
  orderRemoveItemPath1: (orderId, lineItemId) =>
    `${platformPath}/orders/${orderId}/line_items/${lineItemId}`,
  consignmentPath: (consignmentId) =>
    `${platformPath}/consignments/${consignmentId}`,
  consignmentAddItemsPath: (consignmentId) =>
    `${platformPath}/consignments/${consignmentId}/add_items`,
  consignmentDiscountAddItemsPath: (consignmentId) =>
    `${platformPath}/consignments/${consignmentId}/discount`,
  consignmentLineItemsPath: () => `${platformPath}/consignment_line_items`,
  consignmentLineItemsSavePath: (consignmentId, itemId) =>
    `${platformPath}/consignments/${consignmentId}/line_items/${itemId}`,
  cLineItemsResetCommissionPath: (itemId) =>
    `${platformPath}/consignment_line_items/${itemId}/reset_commission`,
  consignmentsPath: () => `${platformPath}/consignments`,
  imagesPath: () => `${platformPath}/images`,
  imagePath: (id) => `${platformPath}/images/${id}`,
  userPath: (userId: string) => `${platformPath}/users/${userId}`,
  userAddressesPath: (userId: string) =>
    `${platformPath}/users/${userId}/addresses`,
  payoutMethodsPath: (userId: string) =>
    `${platformPath}/users/${userId}/payout_methods`,
  payoutMethodPath: (userId: string, payout_method_id: string) =>
    `${platformPath}/users/${userId}/payout_methods/${payout_method_id}`,
  rolesPath: () => `${platformPath}/roles`,
  addressPath: (id: string) => `${platformPath}/addresses/${id}`,
  addressesPath: () => `${platformPath}/addresses`,
  payoutPath: (payoutId) => `${platformPath}/payouts/${payoutId}`,
  refreshPayoutPath: (payoutId) =>
    `${platformPath}/payouts/${payoutId}/regenerate_items`,
  addPayoutItemPath: (payoutId) =>
    `${platformPath}/payouts/${payoutId}/payout_items`,
  addPayoutItemsByConsignmentIdPath: (payoutId) =>
    `${platformPath}/payouts/${payoutId}/add_consignment`,
  removeAllItemsPath: (payoutId) =>
    `${platformPath}/payouts/${payoutId}/remove_all_items`,
  updatePayoutStatePath: (payoutId) =>
    `${platformPath}/payouts/${payoutId}/update_state`,
  payoutsPath: () => `${platformPath}/payouts`,
  payoutItemsPath: (payoutId, itemId) =>
    `${platformPath}/payouts/${payoutId}/payout_items/${itemId}`,
  salesReportPath: () => `${platformPath}/reports/sales`,
  performancesReportPath: () => `${platformPath}/reports/performances`,
  productsReportPath: () => `${platformPath}/reports/products`,
  consignmentsReportPath: () => `${platformPath}/reports/consignments`,
  salesOldReportPath: () => `${platformPath}/reports/sales_old`,
  paymentsPath: () => `${platformPath}/payments`,
  paymentPath: (id) => `${platformPath}/payments/${id}`,
  paymentStatePath: (id) => `${platformPath}/payments/${id}/update_state`,
  deliveryFeePath: () => `${platformPath}/delivery/fee`,
  deliveryFreeShipAmountPath: () => `${platformPath}/delivery/free_ship`,
  shipmentPath: (id) => `${platformPath}/shipments/${id}`,
  shipmentShipPath: (id) => `${platformPath}/shipments/${id}/ship`,
  shipmentPlacePath: (id) => `${platformPath}/shipments/${id}/place`,
  toolProductFbDesPath: () => `${platformPath}/product_fb_des`,
  logMultiProductsPath: () => `${platformPath}/log_multi_products`,
  toolProductShortDesPath: () => `${platformPath}/product_short_des`,
  downloadTextedPhotosPath: () => `${platformPath}/download_texted_photos`,
  clearStockLocationAreaPath: () => `${platformPath}/clear_stock_location_area`,
  reCountStockLocationAreaPath: () =>
    `${platformPath}/re_count_stock_location_area`,
  shiftTablePath: () => `${platformPath}/get_shift_table_v2`,
  updateTaxonProductCountPath: () => `${platformPath}/update_product_count`,
  logEntryPath: () => `${platformPath}/log_entries`,
  stockLocationsPath: () => `${platformPath}/stock_locations`,
  stockLocationAreasPath: () => `${platformPath}/stock_location_areas`,
  attendancesPath: () => `${platformPath}/attendances`,
  attendancePath: (id) => `${platformPath}/attendances/${id}`,
  attendanceLogsPath: () => `${platformPath}/attendance_logs`,
  attendanceLogPath: (id) => `${platformPath}/attendance_logs/${id}`,
  stockItemsPath: () => `${platformPath}/stock_items`,
  stockItemPath: (id) => `${platformPath}/stock_items/${id}`,
  storeCreditsPath: () => `${platformPath}/store_credits`,
  storeCreditPath: (id) => `${platformPath}/store_credits/${id}`,
  adjustmentsPath: () => `${platformPath}/adjustments`,
  adjustmentPath: (id) => `${platformPath}/adjustments/${id}`,
  cmsPagesPath: () => `${platformPath}/cms_pages`,
  cmsPagePath: (id) => `${platformPath}/cms_pages/${id}`,
}
