import { ApiContext } from '../../types'
import getCurrentBearerToken from '../authentication/getCurrentBearerToken'

export default async function updateTaxonProductCount({
  client,
  config,
}: ApiContext) {
  const bearerToken = await getCurrentBearerToken({ client, config })

  const response = await client.tools.updateTaxonProductCount({ bearerToken })
  return response.isSuccess()
}
