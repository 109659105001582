import { ApiContext } from '../../types'
import getCurrentBearerToken from '../authentication/getCurrentBearerToken'

import { deserializeConsignment } from '../serializers/consignment'
import { IQuery } from '@/api_client/interfaces/Query'

export default async function getConsignments(
  { client, config }: ApiContext,
  params: IQuery = {},
): Promise<any> {
  try {
    const token = await getCurrentBearerToken({
      client,
      config,
    })

    if (
      params.filter &&
      params.filter.state_eq &&
      params.filter.state_eq.toString().length > 0 &&
      params.filter.state_eq.toString()[0] == '~'
    ) {
      params.filter.state_not_in = ['complete', 'completed']
      params.filter.state_eq = null
    }
    console.log(params)
    const response = await client.consignments.list(
      { bearerToken: token },
      {
        ...params,
        per_page: params.per_page || 10,
        sort:
          params.sort == null || params.sort.length == 0 ? '-id' : params.sort,
        include: 'user',
      },
    )
    if (response.isSuccess()) {
      const payload = response.success()
      // console.log(payload)
      const data = payload.data.map((order) =>
        deserializeConsignment(order, payload.included, config),
      )
      return { ...payload, data }
    } else {
      throw response.fail()
    }
  } catch (e) {
    console.error(e)
    throw e
  }
}
