import { ApiContext, Image } from '../../types'
import getCurrentBearerToken from '@/api_client/api/authentication/getCurrentBearerToken'
import {
  addHostToImage,
  deserializeImage,
} from '@/api_client/api/serializers/image'

export default async function addImage(
  { client, config }: ApiContext,
  variantId,
  image,
  viewable_type = 'Spree::Variant',
): Promise<Image> {
  try {
    const token = await getCurrentBearerToken({
      client,
      config,
    })

    const data = new FormData()
    data.append('image[attachment]', image)
    data.append('image[viewable_id]', variantId.toString())
    data.append('image[viewable_type]', viewable_type)
    const result = await client.images.create({ bearerToken: token }, data)

    if (result.isSuccess()) {
      const data = result.success().data

      return deserializeImage(addHostToImage(data, config))
    } else {
      throw result.fail()
    }
  } catch (e) {
    console.error(e)
    throw e
  }
}
