import { ApiContext } from '../../types'
import getCurrentBearerToken from '@/api_client/api/authentication/getCurrentBearerToken'
import { RelationType } from '@spree/storefront-api-v2-sdk/types/interfaces/Relationships'
import { addHostToProductImages } from '@/api_client/api/serializers/image'
import { deserializeProduct } from '@/api_client/api/serializers/product'

export default async function saveProduct(
  { client, config }: ApiContext,
  params,
  productId = null,
  isIncludeTaxons = true,
): Promise<any> {
  try {
    const token = await getCurrentBearerToken({
      client,
      config,
    })

    const taxon_ids = []
    const taxon_id_keys = [
      'brand_id',
      'category_id',
      'condition_id',
      'color_id',
      'size_id',
      'material_id',
      'discount_id',
    ]
    for (const key of taxon_id_keys) {
      if (params.product[key]) {
        taxon_ids.push(params.product[key])
      }
    }

    if (params.product.pattern_ids) {
      for (const key of params.product.pattern_ids) {
        taxon_ids.push(key)
      }
    }

    if (params.product.style_ids) {
      for (const key of params.product.style_ids) {
        taxon_ids.push(key)
      }
    }

    const include =
      'default_variant,default_variant.stock_items,taxons,product_properties,properties,images,discounts'
    let result
    if (!productId) {
      result = await client.products.create({ bearerToken: token }, productId, {
        product: {
          ...params.product,
          taxon_ids: isIncludeTaxons ? taxon_ids : null,
        },
        include: include,
      })
    } else {
      result = await client.products.update({ bearerToken: token }, productId, {
        product: {
          ...params.product,
          taxon_ids: isIncludeTaxons ? taxon_ids : null,
        },
        include: include,
        // ...params,

        // fields: {
        //   product:
        //     'name,slug,sku,description,primary_variant,default_variant,variants,option_types,taxons,added_to_cart_at,price,cost_price,compare_at_price',
        //   variant:
        //     'sku,price,cost_price,display_price,in_stock,product,images,option_values,is_master',
        // },
        // include:
        //   'default_variant,variants.option_values,option_types,taxons,images,product_properties,properties,orders',
      })
    }

    if (result.isSuccess()) {
      const data = result.success()
      const productsData = addHostToProductImages(data, config)
      const r = deserializeProduct(productsData)
      return r
      // const theProduct = data.data
      // console.log(data.data)
      // let variant = null
      // if (data.included) {
      //   variant = data.included.find(
      //     (a) =>
      //       a.type === 'variant' &&
      //       a.id ===
      //         (data.data.relationships.default_variant.data as RelationType).id,
      //   )
      // }
      //
      // return {
      //   id: theProduct.id,
      //   price: variant?.attributes?.price,
      //   sku: theProduct.attributes.sku,
      // }
      // return cart
    } else {
      throw result.fail()
    }
  } catch (e) {
    console.error(e)
    throw e
  }
}
