export const messages = {
  en: {
    SKU: 'SKU',
    hello: 'H',
    'Initial estimate price': 'Initial estimate price',
    'New Consignment': 'New Consignment',
    Consignment: 'Consignment',
    Customer: 'Customer',
    State: 'State',
    Remark: 'Remark',
    'Received at': 'Received at',
    'Record created at': 'Record created at',
    deliver_to_store: 'Waiting for delivery',
    received: 'Received',
    Checking: 'Checking',
    'Customer review': 'Customer review',
    launch: 'Launch',
    launched: 'Launched',
    complete: 'Complete',
    Return: 'Return',
    Brand: 'Brand',
    Save: 'Save',
    Info: 'Info',
    Prices: 'Prices',
    Status: 'Status',
    Actions: 'Actions',
    Name: 'Name',
    Category: 'Category',
    Condition: 'Condition',
    Product: 'Product',
    Available: 'Available',
    Sold: 'Sold',
    Commission: 'Commission',
    Remove: 'Remove',
    Create: 'Create',
    Select: 'Select',
    Description: 'Description',
    Saved: 'Saved',
    Delete: 'Delete',
    Price: 'Price',
    Discount: 'Discount',
    Properties: 'Properties',
    Size: 'Size',
    Material: 'Material',
    Color: 'Color',
    Patterns: 'Patterns',
    Measurements: 'Measurements',
    bust: 'bust',
    length: 'length',
    hips: 'hips',
    waist: 'waist',
    inseam: 'inseam',
    rise: 'rise',
    width: 'width',
    height: 'height',
    heel: 'heel',
    Search: 'Search',
    Filters: 'Filters',
    Clear: 'Clear',
    Images: 'Images',
    Cancel: 'Cancel',
    Order: 'Order',
    Channel: 'Channel',
    Pickup: 'Pickup',
    Delivery: 'Delivery',
    Subtotal: 'Subtotal',
    Total: 'Total',
    Shop: 'Shop',
    Facebook: 'Facebook',
    Website: 'Website',
    App: 'App',
    Guest: 'Guest',
    Records: 'Records',
    Styles: 'Styles',
    Pending: 'Pending',
    Expired: 'Expired',
    'Retail price': 'Retail price',
    'Current price': 'Current price',
    'Brand size': 'Brand size',
    'Select Customer': 'Select Customer',
    'Select product': 'Select product',
    'Order note': 'Order note',
    'Payment method': 'Payment Method',
    'Delivery fee': 'Delivery fee',
    'Store Credits': 'Store Credits',
    'Please go to product page after created':
      'Please go to product page after created',
    'Available On': 'Available On',
    in_stock: 'In stock',
    'Condition note': 'Condition note',
    'Discontinue On': 'Discontinue On',
    'Admin remark': 'Admin remark',
    'Auto discount': 'Auto discount',
    'Sell price': 'Sell price',
    'Original price': 'Original price',
    'User purchase price': 'User purchase price',
    'User Suggested price': 'User Suggested price',
    'Confirmed price': 'Confirmed price',
    'Payment total': 'Payment total',
    'Add items': 'Add items',
    'Customer Revenue': 'Customer Revenue',
    'Are you sure to delete this item?': 'Are you sure to delete this item?',
    Number: 'Number',
    Email: 'Email',
    Phone: 'Phone',
    Close: 'Close',
    Logout: 'Logout',
    Detail: 'Detail',
    Address: 'Address',
    Province: 'Province',
    Backend: 'Backend',
    District: 'District',
    Staff: 'Staff',
    Orders: 'Orders',
    Surcharges: 'Surcharge',
    'Cash change': 'Cash change',
    'Customer pay': 'Customer pay',
    'Thank you': 'Thank you',
    'Expire date': 'Expire date',
    'Expire at': 'Expire at',
    'No records found': 'No records found',
    'Print Invoice': 'Print Invoice',
    'Completed at': 'Completed at',
    'Order number': 'Order number',
    'Total number of items': 'Total number of items',
    Approver: 'Approver',
    'Sale distribution': 'Sale distribution',
    'Payment methods': 'Payment methods',
    'Sale channels': 'Sale channels',
    'Sale total': 'Sale total',
    'Number of bills': 'Number of bills',
    'Number of items': 'Number of items',
    'Discount total': 'Discount total',
    'Item total': 'Item total',
    'Total amount of payment methods': 'Total amount of payment methods',
    Apply: 'Apply',
    Date: 'Date',
    'Discount programs': 'Discount programs',
    'Shipping method': 'Shipping method',
    'Payout method': 'Payout method',
    'Previous payout items': 'Previous payout items',
    'Unpaid items': 'Unpaid items',
    'Unsold items': 'Unsold items',
    'Returned items': 'Returned items',
    'Export date': 'Export date',
    'Payment date': 'Payment date',
    'Total amount': 'Total amount',
    'Final selling price': 'Final selling price',
    Payout: 'Payout',
    'Additional information': 'Additional information',
    'No record found': 'No record found',
    Revenue: 'Revenue',
    'Payout items': 'Payout items',
    Approve: 'Approve',
    Paid: 'Paid',
    Refresh: 'Refresh',
    'Print payout bill': 'Print payout bill',
    'Previous payout total': 'Previous payout total',
    'Other items': 'Other items',
    'Payout number': 'Payout number',
    'Payout status': 'Payout status',
    'Thank you for choosing a sustainable lifestyle':
      'Thank you for choosing a sustainable lifestyle',
    'Date of Birth': 'Date of Birth',
    Ward: 'Ward',
    Roles: 'Roles',
    Note: 'Note',
    'Reset password': 'Reset password',
    'Payout methods': 'Payout methods',
    Payouts: 'Payouts',
    Consignments: 'Consignments',
    "Please add this information in 'Customers' > 'Payout methods'":
      "Please add this information in 'Customers' > 'Payout methods'",
    "Please add this information in 'Customers' > 'Info'":
      "Please add this information in 'Customers' > 'Info'",
    Accepted: 'Accepted',
    Returned: 'Returned',
    'Not found': 'Not found',
    Rejected: 'Rejected',
    Mode: 'Mode',
    Direct: 'Direct',
    Percentage: 'Percentage',
    'Total items': 'Total items',
    'Product information': 'Product information',
    'Product name': 'Product name',
    'Cost price': 'Cost price',
    Slug: 'Slug',
    Copy: 'Copy',
    Schedule: 'Schedule',
    Discounts: 'Discounts',
    'Add stock': 'Add stock',
    'Drop stock': 'Drop stock',
    'Add a new brand': 'Add a new brand',
    Approved: 'Approved',
    Payments: 'Payments',
    Adjustment: 'Adjustment',
    'Your revenue': 'Your revenue',
    'Delivery bill': 'Delivery bill',
    'Related consignments': 'Related consignments',
    'Consignment list': 'Consignment list',
    Submit: 'Submit',
    'Remove all': 'Remove all',
    'Paid items': 'Paid items',
    'Pending payment items': 'Pending payment items',
    'Add all': 'Add all',
    Reference: 'Reference',
    donate_complete: 'Donate and complete',
    return_delivery: 'Return - Delivery',
    return_pickup: 'Return - Self pick-up',
    'Gained SC points': 'Gained SC points',
    Unsold: 'Unsold',
    'Pending payment': 'Pending payment',
    'Delivery bill total': 'Delivery bill total',
    'Delivery companies': 'Delivery companies',
    'Applied SC Points': 'Applied SC Points',
    'Current SC Points': 'Current SC Points',
    'Created at': 'Created at',
    'Pick up clothing due': 'Pick up clothing due',
    pick_up_clothing_due_info:
      'Customer have to take back their clothes before this date. Otherwise, extra storage fee will be charged',
    Collecting: 'Collecting',
    'Waiting customer': 'Waiting customer',
    'On sales': 'On sales',
    'Are you sure to discount all items': 'Are you sure to discount all items',
    consignment_discount_consignment:
      'The expire date will be added automatically 14 days',
    consignment_discount_product1:
      'The discontinue date will be added automatically 14 days',
    consignment_discount_product2:
      'The price will be discounted automatically according to the discount percentage.',
    consignment_discount_product3:
      'Corresponding discount tags will be added automatically.',
    'Remaining products': 'Remaining products',
    'Related payouts': 'Related payouts',
    measure_shoot: 'Measure and shoot',
    priced_store: 'Store confirmed price',
    priced_customer: 'Customer confirmed price',
    priced_confirmed: 'Confirmed price',
    'Remaining quantity': 'Remaining quantity',
    Quantity: 'Quantity',
    Customers: 'Customers',
    'Re count': 'Re count',
    'Create directly': 'Create directly',
    'Printed at': 'Printed at',
    'Total number of quantity': 'Total number of quantity',
    'In stock quantity': 'In stock quantity',
  },
  vi: {
    SKU: 'SKU',
    hello: 'Xin chào',
    'Initial estimate price': 'Giá ban đầu',
    'New Consignment': 'Ký gửi mới',
    Consignment: 'Ký gửi',
    Customer: 'Khách hàng',
    State: 'Tình trạng',
    Remark: 'Nhận xét',
    'Received at': 'Nhận tại',
    'Record created at': 'Thông tin được tạo lúc',
    deliver_to_store: 'Đang chờ giao hàng',
    received: 'Đã nhận',
    Checking: 'Đang kiểm tra',
    'Customer review': 'Đợi khách hàng đồng ý',
    launch: 'Ra mắt',
    Launched: 'Ra mắt',
    complete: 'Hoàn thành',
    Return: 'Hoàn trả',
    Brand: 'Thương hiệu',
    Save: 'Lưu',
    Info: 'Thông tin',
    Prices: 'Giá',
    Status: 'Trạng thái',
    Actions: 'Hành động',
    Name: 'Tên',
    Category: 'Danh mục',
    Condition: 'Điều kiện',
    Product: 'Sản phẩm',
    Available: 'Còn hàng',
    Sold: 'Hết hàng',
    Commission: 'Hoa hồng',
    Remove: 'Loại bỏ',
    Create: 'Tạo',
    Select: 'Chọn',
    Description: 'Miêu tả',
    Saved: 'Đã lưu',
    Delete: 'Xóa',
    Price: 'Gía',
    Discount: 'Giảm giá',
    Properties: 'Tính ',
    Size: 'Size',
    Material: 'Chất liệu',
    Color: 'Màu',
    Patterns: 'Hoa văn',
    Measurements: 'Đo',
    bust: 'vòng ngực',
    length: 'chiều dài',
    hips: 'hông',
    waist: 'eo',
    inseam: 'đường may sườn trong',
    rise: 'cạp quần',
    width: 'chiều rộng',
    height: 'chiều cao',
    heel: 'cao gót',
    Search: 'Tìm kiếm',

    Filters: 'Phân loại',
    Clear: 'Xoá',
    Images: 'Hình ảnh',
    Cancel: 'Huỷ',
    Order: 'Đặt',
    Channel: 'Kênh',
    Pickup: 'Lấy hàng',
    Delivery: 'Giao hàng',
    Subtotal: 'Subtotal',
    Total: 'Tổng cộng',
    Shop: 'Cửa hàng',
    Facebook: 'Facebook',
    Website: 'Website',
    App: 'App',
    Guest: 'Khách hàng',
    Records: 'Bản ghi',
    Styles: 'Phong cách',
    Pending: 'Đang chờ xử lý',
    Expired: 'Đã hết hạn',
    'Retail price': 'Giá bán lẻ',
    'Current price': 'Giá hiện tại',
    'Brand size': 'Kích cỡ thương hiệu',
    'Select Customer': 'Chọn khách hàng',
    'Select product': 'Chọn sản phẩm',
    'Order note': 'Ghi chú đơn hàng',
    'Payment method': 'Phương thức thanh toán',
    'Delivery fee': 'Phí giao hàng',
    'Store Credits': 'Tín dụng của cửa hàng',
    'Please go to product page after created':
      'Vui lòng truy cập trang sản phẩm sau khi ',
    'Available On': 'Có sẵn tại',
    in_stock: 'Còn hàng',
    'Condition note': 'Điều kiện lưu ý',
    'Discontinue On': 'Ngừng',
    'Admin remark': 'Nhận xét của quản trị viên',
    'Auto discount': 'Giảm giá tự động',
    'Sell price': 'Giá khuyến mãi',
    'Original price': 'Giá gốc',
    'User purchase price': 'Giá mua của người dùng',
    'User Suggested price': 'Giá do người dùng đề xuất',
    'Confirmed price': 'Giá đã xác nhận',
    'Payment total': 'Tổng tiền phải trả',
    'Add items': 'Thêm sản phẩm',
    'Customer Revenue': 'Doanh thu khách hàng',

    'Are you sure to delete this item?': 'Bạn có chắc muốn xóa sản phẩm?',
    Number: 'Số',
    Email: 'Email',
    Phone: 'Số điện thoại',
    Close: 'Đóng',
    Logout: 'Đăng xuất',
    Detail: 'Chi tiết',
    Address: 'Địa chỉ',
    Province: 'Tỉnh',
    Backend: 'Backend',
    District: 'Quận',
    Staff: 'Nhân viên',
    Orders: 'Đơn hàng',
    Surcharges: 'Phụ phí',
    'Cash change': 'Tiền thối',
    'Customer pay': 'Khách đưa',
    'Thank you': 'Cảm ơn',
    'Expire date': 'Ngày hết hạn',
    'Expire at': 'Ngày hết hạn',
    'No records found': 'Không có dữ liệu',
    'Print Invoice': 'In hoá đơn',
    'Completed at': 'Hoàn thành vào',
    'Order number': 'Mã số đơn hàng',
    'Total number of items': 'Tổng số mặt hàng',
    Approver: 'Người nhận Ký Gửi',
    'Sale distribution': 'Phân phối bán hàng',
    'Payment methods': 'Phương thức thanh toán',
    'Sale channels': 'Kênh Bán Hàng',
    'Sale total': 'Doanh Thu',
    'Number of bills': 'Số lượng bill',
    'Number of items': 'Số lượng sản phẩm',
    'Discount total': 'Tổng giảm giá',
    'Item total': 'Tổng sản phẩm',
    'Total amount of payment methods': 'Hình thức thanh toán',
    Apply: 'Áp dụng',
    Date: 'Ngày',
    'Discount programs': 'Chương trình giảm giá',
    'Shipping method': 'Phương thức gửi hàng',
    'Payout method': 'Phương thức thanh toán',
    'Previous payout items': 'Các mặt hàng đã thanh toán trước đây',
    'Unpaid items': 'Các sản phẩm chưa thanh toán',
    'Unsold items': 'Các sản phẩm chưa bán',
    'Returned items': 'Các sản phẩm hoàn trả',
    'Export date': 'Ngày xuất',
    'Payment date': 'Ngày thanh toán',
    'Total amount': 'Tổng tiền',
    'Final selling price': 'Giá bán cuối cùng',
    Payout: 'Thanh toán',
    'Additional information': 'Thông tin thêm',
    'No record found': 'Không tìm thấy dữ liệu',
    Revenue: 'Doanh thu',
    'Payout items': 'Các mặt hàng thanh toán',
    Approve: 'Duyệt',
    Paid: 'Đã thanh toán',
    Refresh: 'Tải lại',
    'Print payout bill': 'In hoá đơn thanh toán',
    'Previous payout total': 'Tổng thanh toán trước',
    'Other items': 'Các sản phẩm khác',
    'Payout number': 'Mã số thanh toán',
    'Payout status': 'Tình trạng thanh toán',
    'Thank you for choosing a sustainable lifestyle':
      'Cảm ơn bạn đã lựa chọn lối sống tiết kiệm',
    'Date of Birth': 'Ngày sinh',
    Ward: 'Phường',
    Roles: 'Vai trò',
    Note: 'Ghi chú',
    'Reset password': 'Đặt lại mật khẩu',
    'Payout methods': 'Phương thức thanh toán',
    Payouts: 'Thanh toán',
    Consignments: 'Ký gửi',
    "Please add this information in 'Customers' > 'Payout methods'":
      " Vui lòng thêm thông tin  'Khách hàng'  > ‘Phương thức thanh toán",
    "Please add this information in 'Customers' > 'Info'":
      "Vui lòng thêm thông tin  'Khách hàng' > 'Thông tin'",
    Accepted: 'Chấp nhận',
    Returned: 'Hoàn lại',
    'Not found': 'Không tìm thấy',
    Rejected: 'Loại bỏ',
    Mode: 'Chế độ',
    Direct: 'Trực tiếp',
    Percentage: 'Phần trăm',
    'Total items': 'Tổng các sản phẩm',
    'Product information': 'Thông tin sản phẩm',
    'Product name': 'Tên sản phẩm',
    'Cost price': 'Giá bán lẻ ban đầu',
    Slug: 'Mô tả sản phẩm',
    Copy: 'Sao chép',
    Schedule: 'Bản liệt kê',
    Discounts: 'Giảm giá',
    'Add stock': 'Thêm vào kho',
    'Drop stock': 'Giảm số lượng kho',
    'Add a new brand': 'Thêm một thương hiệu mới',
    Approved: 'Đã được phê duyệt',
    Payments: 'Thanh toán',
    Adjustment: 'Điều chỉnh',
    'Your revenue': 'Doanh thu của bạn',
    'Delivery bill': 'Hóa đơn giao hàng',
    'Related consignments': 'Hàng kí gửi liên quan',
    'Consignment list': 'Danh sách kí gửi',
    Submit: 'Nộp',
    'Remove all': 'Xóa bỏ tất cả',
    'Paid items': 'Các mặt hàng phải trả phí',
    'Pending payment items': 'Đang chờ xử lí',
    'Add all': 'Thêm tất cả',
    Reference: 'Tham khảo',
    donate_complete: 'Đóng góp và hoàn thành',
    return_delivery: 'Trả lại hàng hóa - Vận chuyển',
    return_pickup: 'Trả lại hàng hóa - Tự nhận',
    'Gained SC Points': 'Đã đạt được điểm SC',
    Unsold: 'Chưa bán được',
    'Pending payment': 'Trong khi chờ thanh toán',
    'Delivery bill total': 'Tổng hóa đơn giao hàng',
    'Delivery companies': 'Công ty giao hàng',
    'Applied SC Points': 'SC Points được áp dụng',
    'Current SC Points': 'SC Points hiện tại',
    'Created at': 'Được tạo lúc',
    'Pick up clothing due': 'Hạn lấy quần áo',
    pick_up_clothing_due_info:
      'Khách hàng phải lấy quần áo của họ lại trước ngày Return day. Nếu trễ hơn ngày này, khách hàng sẽ bị tính phí lưu kho.',
    Collecting: 'Sưu tập',
    'Waiting customer': 'Khách hàng đang đợi',
    'On sales': 'Đang bán',
    'Are you sure to discount all items':
      'Bạn có chắc sẽ giảm giá các món hàng này không?',
    consignment_discount_consignment:
      'Ngày hết hạn sẽ được tự động cộng thêm 14 ngày.',
    consignment_discount_product1:
      'Ngày ngừng sẽ được tự động cộng thêm 14 ngày.',
    consignment_discount_product2:
      'Giá sẽ được tự động giảm theo phần trăm giảm giá.',
    consignment_discount_product3:
      'Nhãn giảm giá tương ứng sẽ được tự động gắn.',
    'Remaining products': 'Các sản phẩm còn lại.',
    'Related payouts': 'Thanh toán liên quan',
    measure_shoot: 'Đo lường và chụp hình',
    priced_store: 'Giá cửa hàng',
    priced_customer: 'khách hàng định giá',
    priced_confirmed: 'Xác nhận giá',
    // pricing_customer: 'Giá bán (Chờ khách hàng)',
    // pricing_store: 'Giá bán (Cửa hàng)',
    'Remaining quantity': 'Số lượng còn lại',
    Quantity: 'Số lượng',
    Customers: 'Khách hàng',
    'Re count': 'đếm lại',
    'Create directly': 'Tạo trực tiếp',
    'Printed at': 'Được in tại',
    'Total number of quantity': 'Tổng số lượng',
    'In stock quantity': 'Số lượng còn hàng',
  },
}
