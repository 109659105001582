import { ApiContext } from '../../types'
import getCurrentBearerToken from '../authentication/getCurrentBearerToken'
import { deserializeAttendance } from '@/api_client/api/serializers/attendance'

export default async function updateAttendances(
  { client, config }: ApiContext,
  id,
  data,
): Promise<any> {
  try {
    const token = await getCurrentBearerToken({
      client,
      config,
    })
    // console.log({ shift_schedule: data })
    const response = await client.attendances.update(
      { bearerToken: token },
      id,
      { attendance: data },
      { include: 'user' },
    )
    if (response.isSuccess()) {
      const payload = response.success()
      // console.log(payload)
      const data = deserializeAttendance(payload.data, payload.included)

      return data
    } else {
      throw response.fail()
    }
  } catch (e) {
    console.error(e)
    throw e
  }
}
