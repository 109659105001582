import { ApiContext } from '../../types'
import getCurrentBearerToken from '../authentication/getCurrentBearerToken'

import { deserializeConsignmentLineItem } from '../serializers/consignment'
import { addHostToIncluded } from '@/api_client/api/serializers/image'

export default async function getConsignmentLineItems(
  { client, config }: ApiContext,
  consignmentId,
  consignmentNumber,
  useNumber = false,
  consignmentNumbers = '',
  in_stock = false,
): Promise<any> {
  try {
    const token = await getCurrentBearerToken({
      client,
      config,
    })
    const filter = {
      consignment_id_eq: '',
      consignment_number_eq: '',
      consignment_numbers_in: '',
      in_stock: '',
    }
    if (useNumber) {
      filter.consignment_number_eq = consignmentNumber
    } else {
      filter.consignment_id_eq = consignmentId
    }
    if (consignmentNumbers.length > 0) {
      filter.consignment_numbers_in = consignmentNumbers
    }
    if (in_stock) {
      filter.in_stock = 'true'
    }

    const response = await client.consignments.lineItems(
      { bearerToken: token },
      {
        filter,
        include: 'variant,variant.images,discounts',
        sort: 'id',
        per_page: 500,
      },
    )

    if (response.isSuccess()) {
      const payload = response.success()
      // console.log('params[:filter][:consignment_number]')
      console.log(payload)
      payload.included = addHostToIncluded(payload.included, config)

      return payload.data.map((d, index) =>
        deserializeConsignmentLineItem(
          d,
          payload.included,
          config,
          index + 1,
          consignmentNumber,
        ),
      )
    } else {
      throw response.fail()
    }
  } catch (e) {
    console.error(e)
    throw e
  }
}
