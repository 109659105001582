import { Image } from '@/api_client/types/image'
import { ApiConfig } from '@/api_client/types'
import {
  JsonApiDocument,
  JsonApiResponse,
} from '@spree/storefront-api-v2-sdk/types/interfaces/JsonApi'
import { getDatetimeString } from '@/api_client/api/serializers/getters'

export const addHostToImage = (image, config: ApiConfig) => ({
  ...image,
  attributes: {
    ...image.attributes,
    original_url: config.backendUrl + image.attributes?.original_url,
    styles: image.attributes?.styles
      ? image.attributes.styles.map((style) => ({
          width: style.width,
          height: style.height,
          url: config.backendUrl + style.url,
        }))
      : [],
  },
})

export const addHostToIncluded = (
  included: JsonApiDocument[],
  config: ApiConfig,
) => included?.map((e) => (e.type === 'image' ? addHostToImage(e, config) : e))

export const addHostToProductImages = <DocumentType extends JsonApiResponse>(
  apiProductsData: DocumentType,
  config: ApiConfig,
): DocumentType => ({
  ...apiProductsData,
  included: addHostToIncluded(apiProductsData.included, config),
})

export const deserializeImage = (image, included: any[] = null): Image => {
  if (image == null) {
    return null
  }

  const viewable_data = image.relationships?.viewable?.data
  let viewable
  if (included != null && viewable_data != null) {
    viewable = included.find((e) => e.id === viewable_data.id)
  }
  return {
    id: parseInt(image.id, 10),
    original_url: image.attributes.original_url,
    styles: image.attributes.styles.map((style) => ({
      url: style.url,
      width: parseInt(style.width, 10),
      height: parseInt(style.height, 10),
    })),
    position: image.attributes.position,
    created_at: getDatetimeString(image.attributes.created_at),
    viewable: viewable,
  }
}
