import { Address, ApiContext } from '../../types'
// import getCurrentBearerToken from '../authentication/getCurrentBearerToken'
import { serializeAddress } from '../serializers/address'
import getCurrentBearerToken from '@/api_client/api/authentication/getCurrentBearerToken'
import { CmsPage } from '@/api_client/types/CmsPage'
// import getCurrentBearerToken from "@/api_client/api/authentication/getCurrentBearerToken";

export default async function addCmsPage(
  { client, config }: ApiContext,
  cms_page: CmsPage,
): Promise<any> {
  const bearerToken = await getCurrentBearerToken({
    client,
    config,
  })

  const result = await client.cms_pages.create(
    { bearerToken },
    { cms_page: cms_page },
  )

  if (result.isSuccess()) {
    const payload = result.success()
    console.log(payload)
    return payload.data
  } else {
    console.log('result.fail()')
    console.log(result.fail())
    throw result.fail()
  }
}
