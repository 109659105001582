import { ApiContext } from '../../types'
import getCurrentBearerToken from '../authentication/getCurrentBearerToken'
import { deserializeConsignment } from '../serializers/consignment'
import { addHostToIncluded } from '@/api_client/api/serializers/image'

export default async function getConsignment(
  { client, config }: ApiContext,
  consignmentId,
  use_product_id = false,
): Promise<any> {
  try {
    const token = await getCurrentBearerToken({
      client,
      config,
    })

    const params = {
      use_number: '',
      include:
        // 'user,consignment_line_items,consignment_line_items.variant,consignment_line_items.variant.images,approver,user.payout_methods,consignment_line_items.discounts',
        'user,consignment_line_items,consignment_line_items,consignment_line_items,approver,user.payout_methods,consignment_line_items.discounts,images',
      use_product_id: '',
    }

    if (use_product_id) {
      params.use_product_id = 'true'
      params.include = ''
    } else {
      params.use_number = 'true'
    }
    // console.log(params)
    // console.log(consignmentId)
    const response = await client.consignments.show(
      { bearerToken: token },
      consignmentId,
      // {
      //   use_number: true,
      //   include:
      //     'user,consignment_line_items,variants,variants.images,approver,user.payout_methods,consignment_line_items.discounts',
      // },
      params,
    )

    if (response.isSuccess()) {
      const payload = response.success()
      console.log(payload)
      payload.included = addHostToIncluded(payload.included, config)

      return deserializeConsignment(payload.data, payload.included, config)
    } else {
      throw response.fail()
    }
  } catch (e) {
    console.error(e)
    throw e
  }
}
