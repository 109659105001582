import { ApiContext } from '../../types'
import getCurrentBearerToken from '@/api_client/api/authentication/getCurrentBearerToken'

export default async function downloadTextedPhotos(
  { client, config }: ApiContext,
  params,
): Promise<boolean> {
  try {
    // this.http
    //   .get(client.backendPath + `/api/v2/platform/download_texted_photos`, {
    //     data: params,
    //     responseType: 'blob',
    //   })
    //   .then((response) => {
    //     console.log(response)
    //
    //     const blob = new Blob([response.data], { type: 'zip' }),
    //       url = window.URL.createObjectURL(blob)
    //
    //     window.open(url) // Mostly the same, I was just experimenting with different approaches, tried link.click, iframe and other solutions
    //   })
    // return true
    //
    //
    // const token = await getCurrentBearerToken({
    //   client,
    //   config,
    // })

    const result = await client.tools.downloadTextedPhotos({}, params)

    if (result.isSuccess()) {
      return true
    } else {
      throw result.fail()
    }
  } catch (e) {
    console.error(e)
    throw e
  }
}
