import type { ApiContext } from '../../types'
import { deserializeProducts } from '../serializers/product'
import getCurrentBearerToken from '@/api_client/api/authentication/getCurrentBearerToken'
import { addHostToProductImages } from '@/api_client/api/serializers/image'

export default async function getProducts(
  { client, config }: ApiContext,
  params,
  { keyword = '', showTaxons = true, showStockItems = true, extraInclude = '' },
) {
  try {
    let include =
      extraInclude.length > 0
        ? extraInclude + ',default_variant,images'
        : 'default_variant,images'
    if (showTaxons) {
      include += ',taxons'
    }

    if (showStockItems) {
      include += ',default_variant.stock_items'
    }
    // console.log('params')
    // console.log(params)
    const filter = {
      ...params.filter,
      in_stock: params.filter?.in_stock,
    }
    const token = await getCurrentBearerToken({ client, config })
    const result = await client.products.list(
      { bearerToken: token },
      {
        filter,
        include,
        mode: params.mode,
        page: params.page,
        sort: params.sort,
        sort_by: params.sort_by,
        per_page: params.per_page || 10,
      },
    )

    if (result.isSuccess()) {
      const data = result.success()
      const productsData = addHostToProductImages(data, config)

      return {
        data: deserializeProducts(productsData, productsData.included),
        meta: data.meta,
        keyword: keyword,
      }
    } else {
      console.error(result.fail())
      throw result.fail()
    }
  } catch (e) {
    console.error(e)
    throw e
  }
}
