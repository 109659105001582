import { ApiContext } from '../../types'
import getCurrentBearerToken from '../authentication/getCurrentBearerToken'

export default async function updateCmsPage(
  { client, config }: ApiContext,
  cms_page_id,
  cms_page,
): Promise<any> {
  try {
    const token = await getCurrentBearerToken({
      client,
      config,
    })

    // if (params.filter) {
    //   params.filter.number_cont = params.filter.number
    //   params.filter.state_cont = params.filter.state
    // }
    const response = await client.cms_pages.update(
      { bearerToken: token },
      cms_page_id,
      { cms_page: cms_page },
    )
    if (response.isSuccess()) {
      const payload = response.success()
      console.log(payload)
      // const data = payload.data.map((order) =>
      //   deserializePayout(order, payload.included, config),
      // )
      // return { ...payload, data }
      return true
    } else {
      throw response.fail()
    }
  } catch (e) {
    console.error(e)
    throw e
  }
}
