// import { Logger } from '@vue-storefront/core'
import { ApiContext } from '../../types'
import getCurrentBearerToken from '../authentication/getCurrentBearerToken'

export default async function shipOrderItem(
  { client, config }: ApiContext,
  line_item_id,
): Promise<any> {
  try {
    const token = await getCurrentBearerToken({ client, config })
    const response = await client.account.shipOrderItem(
      { bearerToken: token },
      line_item_id,
    )

    if (response.isSuccess()) {
      const payload = response.success()
      // console.log(payload)
      return true
    } else {
      console.log(response.fail())
      // throw response.fail()
      return false
    }
  } catch (e) {
    console.error(e)
    throw e
  }
}
