import { ApiContext } from '../../types'
import getCurrentBearerToken from '../authentication/getCurrentBearerToken'
import { deserializeTaxon } from '@/api_client/api/serializers/taxon'
import { IQuery } from '@/api_client/interfaces/Query'

export default async function getTaxons(
  { client, config }: ApiContext,
  params: IQuery = {},
  // permalink_in = '',
  // nameKeyword = '',
  // parentPermalink = '',
  // taxonId = '',
) {
  const bearerToken = await getCurrentBearerToken({ client, config })

  const response = await client.taxons.list(
    { bearerToken },
    {
      filter: {
        ...params.filter,
        id_eq: params.filter.taxonId,
        permalink_in:
          params.filter.permalink_in == null
            ? null
            : params.filter.permalink_in.toString().split(','),
        name_cont: params.filter.name_cont,
        parent_permalink: params.filter.parentPermalink,
        depth_eq: params.filter.depth_eq || '',
      },
      include: params.include || '',
      sort: params.sort || 'id',
      per_page: params.per_page || 10,
      page: params.page || 1,
    },
  )
  if (response.isSuccess()) {
    const payload = response.success()
    console.log('payload')
    console.log(payload)
    // const data = deserializeTaxons(payload.data, payload.included)
    const data = payload.data.map((d) =>
      deserializeTaxon(d, payload.included || []),
    )
    return { ...payload, data }
  } else {
    console.log(response.fail())
    return null
  }
}
