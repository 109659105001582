import { ApiConfig } from '@/api_client/types'
import { AuthHelper, makeClient } from '@/api_client/index'
import ChatClient from '@/chat_client/ChatClient'
import getMessages from '@/chat_client/api/getMessages'
import getRooms from '@/chat_client/api/getRooms'

export type ApiContext = {
  client: ChatClient
  config: ApiConfig
}

class ApiChatClient {
  public client: ChatClient
  public config: any
  private apiContext: ApiContext
  // public backendPath =
  //   process.env.NODE_ENV === 'production'
  //     ? 'http://171.244.33.6:4000/'
  //     : 'http://localhost:4000/'

  public backendPath = 'http://localhost:8000/'

  constructor(isHttps = true) {
    const theBackendPath = isHttps
      ? 'https://b.secondcorner.vn/'
      : this.backendPath
    this.client = new ChatClient({
      host: theBackendPath,
    })
    this.config = { auth: new AuthHelper(), backendUrl: theBackendPath }
    this.apiContext = { client: this.client, config: this.config }
  }

  protected getMessages(roomId) {
    return getMessages(this.apiContext, { room_id: roomId })
  }

  protected getRooms() {
    return getRooms(this.apiContext)
  }
}

export default ApiChatClient
