import Http from '../Http'
import { IQuery } from '../interfaces/Query'
import { Routes } from '../routes'
import {
  IAccountResult,
  IAccountsResult,
} from '@/api_client/interfaces/Account'

export default class Users extends Http {
  public async list(token, params: IQuery = {}): Promise<IAccountsResult> {
    return (await this.spreeResponse(
      'get',
      Routes.usersPath(),
      token,
      params,
    )) as IAccountsResult
  }

  public async show(
    token,
    id: string,
    params: IQuery = {},
  ): Promise<IAccountResult> {
    return (await this.spreeResponse(
      'get',
      Routes.userPath(id),
      token,
      params,
    )) as IAccountResult
  }

  public async getPayoutMethods(
    token,
    id: string,
    params: IQuery = {},
  ): Promise<any> {
    return (await this.spreeResponse(
      'get',
      Routes.payoutMethodsPath(id),
      token,
      params,
    )) as any
  }

  public async createPayoutMethods(
    token,
    id: string,
    params = {},
  ): Promise<any> {
    return (await this.spreeResponse(
      'post',
      Routes.payoutMethodsPath(id),
      token,
      params,
    )) as any
  }

  public async updatePayoutMethods(
    token,
    id: string,
    payout_method_id: string,
    params: IQuery = {},
  ): Promise<any> {
    return (await this.spreeResponse(
      'patch',
      Routes.payoutMethodPath(id, payout_method_id),
      token,
      params,
    )) as any
  }

  public async deletePayoutMethods(
    token,
    id: string,
    payout_method_id: string,
  ): Promise<any> {
    return (await this.spreeResponse(
      'delete',
      Routes.payoutMethodPath(id, payout_method_id),
      token,
      {},
    )) as any
  }

  public async roles(token): Promise<any> {
    return await this.spreeResponse('get', Routes.rolesPath(), token, {})
  }

  public async addresses(token, params: IQuery = {}): Promise<IAccountsResult> {
    return (await this.spreeResponse(
      'get',
      Routes.usersPath(),
      token,
      params,
    )) as IAccountsResult
  }
}
