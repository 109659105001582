import { ApiContext } from '../../types'
import getCurrentBearerOrCartToken from '../authentication/getCurrentBearerOrCartToken'
import { deserializeStockLocation } from '@/api_client/api/serializers/stock_location'
import { StockLocation } from '@/api_client/types/stock_location'
import { IQuery } from '@/api_client/interfaces/Query'
import { deserializeStockLocationArea } from '@/api_client/api/serializers/stock_location_area'

export default async function getStockLocationAreas(
  { client, config }: ApiContext,
  params: IQuery = {},
): Promise<StockLocation[]> {
  const token = await getCurrentBearerOrCartToken({ client, config })
  const result = await client.stockLocationArea.show(token, {
    ...params,
    sort: params.sort || 'name',
    page: params.page || 1,
    per_page: params.per_page || 10,
  })
  if (result.isSuccess()) {
    const payload = result.success()
    const included = payload.included
    const data = payload.data.map((d) =>
      deserializeStockLocationArea(d, included),
    )
    return { ...payload, data }
  } else {
    throw result.fail()
  }
}
