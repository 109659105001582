import { Adjustment, ApiContext } from '../../types'
import { deserializeAdjustment } from '../serializers/cart'
import getCurrentBearerToken from '@/api_client/api/authentication/getCurrentBearerToken'

export default async function createAdjustment(
  { client, config }: ApiContext,
  order_id,
  data,
): Promise<Adjustment> {
  try {
    const token = await getCurrentBearerToken({
      client,
      config,
    })

    const result = await client.adjustment.create(
      { bearerToken: token },
      { adjustment: { order_id, ...data } },
    )

    if (result.isSuccess()) {
      const payload = result.success()
      return deserializeAdjustment(payload.data)
    } else {
      console.error(result.fail())
      throw result.fail()
    }
  } catch (e) {
    console.error(e)
    throw e
  }
}
