import { ApiContext } from '../../types'
import getCurrentBearerToken from '../authentication/getCurrentBearerToken'

import { IQuery } from '@/api_client/interfaces/Query'
import {
  addHostToImage,
  addHostToIncluded,
  deserializeImage,
} from '@/api_client/api/serializers/image'

export default async function getImages(
  { client, config }: ApiContext,
  params: IQuery = {},
): Promise<any> {
  try {
    const token = await getCurrentBearerToken({
      client,
      config,
    })

    if (
      params.filter &&
      params.filter.state_eq &&
      params.filter.state_eq.toString().length > 0 &&
      params.filter.state_eq.toString()[0] == '~'
    ) {
      params.filter.state_not_in = ['paid']
      params.filter.state_eq = null
    }

    const response = await client.images.list(
      { bearerToken: token },
      {
        ...params,
        per_page: params.per_page || 10,
        sort: '-id',
        include: 'viewable',
      },
    )
    if (response.isSuccess()) {
      const payload = response.success()
      payload.included = addHostToIncluded(payload.included, config)
      const data = payload.data.map((img) => {
        return deserializeImage(addHostToImage(img, config), payload.included)
      })
      return { ...payload, data }
    } else {
      throw response.fail()
    }
  } catch (e) {
    console.error(e)
    throw e
  }
}
