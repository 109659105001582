import { ApiContext } from '../../types'
import getCurrentBearerToken from '@/api_client/api/authentication/getCurrentBearerToken'

export default async function addItemsToConsignment(
  { client, config }: ApiContext,
  consignmentId,
  quantity,
): Promise<boolean> {
  try {
    const token = await getCurrentBearerToken({
      client,
      config,
    })

    const result = await client.consignments.addItems(
      { bearerToken: token },
      consignmentId,
      {
        quantity,
      },
    )

    if (result.isSuccess()) {
      return true
    } else {
      throw result.fail()
    }
  } catch (e) {
    console.error(e)
    throw e
  }
}
