import { ApiContext } from '../../types'
import getCurrentBearerToken from '../authentication/getCurrentBearerToken'

import { IQuery } from '@/api_client/interfaces/Query'
import { deserializeCMSPage } from '@/api_client/api/serializers/cms_page'
import { addHostToIncluded } from '@/api_client/api/serializers/image'

export default async function getCmsPage(
  { client, config }: ApiContext,
  id,
  params: IQuery = {},
): Promise<any> {
  try {
    const token = await getCurrentBearerToken({
      client,
      config,
    })

    const response = await client.cms_pages.show({ bearerToken: token }, id, {
      ...params,
      include: params.include || 'images',
    })
    if (response.isSuccess()) {
      const payload = response.success()
      console.log(payload)
      // const data = payload.data.map((order) =>
      //   deserializeCMSPage(order, payload.included, config),
      // )
      payload.included = addHostToIncluded(payload.included, config)
      return deserializeCMSPage(payload.data, payload.included, config)
    } else {
      throw response.fail()
    }
  } catch (e) {
    console.error(e)
    throw e
  }
}
