import Http from '../Http'
import { IQuery } from '../interfaces/Query'
import { IToken } from '../interfaces/Token'
import { Routes } from '../routes'
import {
  IConsignmentResult,
  IConsignmentsResult,
} from '@/api_client/interfaces/Consignment'

export default class Consignments extends Http {
  public async list(
    token: IToken,
    params: IQuery = {},
  ): Promise<IConsignmentsResult> {
    return (await this.spreeResponse(
      'get',
      Routes.consignmentsPath(),
      token,
      params,
    )) as IConsignmentsResult
  }

  public async show(
    token: IToken,
    consignmentId,
    params: IQuery = {},
  ): Promise<IConsignmentResult> {
    return (await this.spreeResponse(
      'get',
      Routes.consignmentPath(consignmentId),
      token,
      params,
    )) as IConsignmentResult
  }

  public async delete(
    token: IToken,
    orderId: number,
  ): Promise<IConsignmentResult> {
    return (await this.spreeResponse(
      'delete',
      Routes.consignmentPath(orderId),
      token,
    )) as IConsignmentResult
  }

  public async create(
    token?: IToken,
    params: IQuery = {},
  ): Promise<IConsignmentResult> {
    return (await this.spreeResponse(
      'post',
      Routes.consignmentsPath(),
      token,
      params,
    )) as IConsignmentResult
  }

  public async update(
    token: IToken,
    orderId: number,
    params: any,
    data: any,
  ): Promise<IConsignmentResult> {
    return (await this.spreeResponse(
      'patch',
      Routes.consignmentPath(orderId),
      token,
      params,
      data,
    )) as IConsignmentResult
  }

  public async lineItems(token: IToken, params: any): Promise<any> {
    return await this.spreeResponse(
      'get',
      Routes.consignmentLineItemsPath(),
      token,
      params,
    )
  }

  public async discountAllItems(
    token: IToken,
    consignmentId: number,
    params: any,
  ): Promise<any> {
    return await this.spreeResponse(
      'patch',
      Routes.consignmentDiscountAddItemsPath(consignmentId),
      token,
      params,
    )
  }

  public async addItems(
    token: IToken,
    consignmentId: number,
    params: any,
  ): Promise<IConsignmentResult> {
    return (await this.spreeResponse(
      'post',
      Routes.consignmentAddItemsPath(consignmentId),
      token,
      params,
    )) as IConsignmentResult
  }

  public async updateItem(
    token: IToken,
    id: string,
    lineItemId: number,
    params: any,
    data: any,
  ): Promise<any> {
    return await this.spreeResponse(
      'patch',
      Routes.consignmentLineItemsSavePath(id, lineItemId),
      token,
      params,
      data,
    )
  }

  public async removeItem(
    token: IToken,
    id: string,
    lineItemId: number,
  ): Promise<any> {
    return await this.spreeResponse(
      'delete',
      Routes.consignmentLineItemsSavePath(id, lineItemId),
      token,
      {},
    )
  }

  public async resetItemCommission(
    token: IToken,
    lineItemId: string,
  ): Promise<any> {
    return await this.spreeResponse(
      'patch',
      Routes.cLineItemsResetCommissionPath(lineItemId),
      token,
    )
  }
}
