import { ApiContext, Cart } from '../../types'
import { deserializeCart } from '../serializers/cart'
import getCurrentBearerToken from '@/api_client/api/authentication/getCurrentBearerToken'

export default async function setOrderShippingMethod(
  { client, config }: ApiContext,
  orderId,
  shipping_method_id,
): Promise<Cart> {
  try {
    const token = await getCurrentBearerToken({
      client,
      config,
    })

    const result = await client.cart.setShippingMethod(
      { bearerToken: token },
      orderId,
      {
        shipping_method_id,
      },
    )

    if (result.isSuccess()) {
      const payload = result.success()
      const cart = deserializeCart(payload.data, payload.included, config)
      return cart
    } else {
      throw result.fail()
    }
  } catch (e) {
    console.error(e)
    throw e
  }
}
