import { ApiContext } from '../../types'
import getCurrentBearerToken from '../authentication/getCurrentBearerToken'

export default async function getFreeShipAmount({
  client,
  config,
}: ApiContext) {
  const bearerToken = await getCurrentBearerToken({ client, config })

  const response = await client.delivery.free_ship({ bearerToken })
  if (response.isSuccess()) {
    return response.success().free_ship
  } else {
    return null
  }
}
