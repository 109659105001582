import { deserializeUser } from '@/api_client/api/serializers/user'
import { AttendanceLog } from '@/api_client/types/attendance_log'

const findAttachment = (attachments: any[], type: string, id) => {
  return attachments?.find(
    (e) => e.type === type && e.id.toString() === id.toString(),
  )
}

export const deserializeAttendanceLog = (
  attr: any,
  include,
): AttendanceLog => ({
  id: attr.id,
  user_id: attr.attributes.user_id,
  attendance_at: attr.attributes.attendance_at,
  attendance_location: attr.attributes.attendance_location,
  attendance_latitude: attr.attributes.attendance_latitude,
  attendance_longitude: attr.attributes.attendance_longitude,
  device_name: attr.attributes.device_name,
  device_os: attr.attributes.device_os,
  device_id: attr.attributes.device_id,
  record_ip: attr.attributes.record_ip,
  notes: attr.attributes.notes,
  created_at: attr.attributes.created_at,
  updated_at: attr.attributes.updated_at,
  created_by_id: attr.attributes.created_by_id,
  record_type: attr.attributes.record_type,
  user: deserializeUser(
    findAttachment(include, 'user', attr.relationships['user']['data']['id']),
    include,
  ),
  created_by: deserializeUser(
    findAttachment(
      include,
      'user',
      attr.relationships['created_by']['data']['id'],
    ),
    include,
  ),
})
