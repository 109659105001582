import { CouponCode as CouponCodeAttr } from '@spree/storefront-api-v2-sdk/types/interfaces/endpoints/CartClass'
import { CouponCode } from '../../types'
import { RelationType } from '@spree/storefront-api-v2-sdk/types/interfaces/Relationships'
import { getDatetimeString } from '@/api_client/api/serializers/getters'
import {
  deserializePayoutMethod,
  deserializeUser,
} from '@/api_client/api/serializers/user'
import { PayoutAttr } from '@/api_client/interfaces/Payout'
import { Payout, PayoutItem } from '@/api_client/types/payout'
import { deserializeConsignmentLineItem } from '@/api_client/api/serializers/consignment'
import { deserializeImage } from '@/api_client/api/serializers/image'

const findAttachment = (attachments: any[], id: string, type: string) => {
  if (id == null) {
    return null
  }
  return attachments?.find(
    (e) => e.id.toString() === id.toString() && e.type === type,
  )
}

export const deserializePayoutItem = (
  payoutItem: any,
  attachments: any[],
  config: any[],
  index,
): PayoutItem => {
  // let variant = null
  // let inStock = true
  // let image = null

  const consignment_line_item = deserializeConsignmentLineItem(
    findAttachment(
      attachments,
      payoutItem.relationships.consignment_line_item.data.id,
      payoutItem.relationships.consignment_line_item.data.type,
    ),
    attachments,
    config,
    index,
    payoutItem.attributes.consignment_number,
  )

  return {
    id: parseInt(payoutItem.id, 10),
    payout_id: payoutItem.attributes.payout_id,
    quantity: payoutItem.attributes.quantity,
    consignment_line_item_id: payoutItem.attributes.consignment_line_item_id,
    consignment_line_item: consignment_line_item,
    variant: consignment_line_item.product,
    consignment_number: payoutItem.attributes.consignment_number,
    index: index + 1,
  }
}

// export const deserializeImage = (image): Image => {
//   if (image == null) {
//     return null
//   }
//
//   return {
//     id: parseInt(image.id, 10),
//     original_url: image.attributes.original_url,
//     styles: image.attributes.styles.map((style) => ({
//       url: style.url,
//       width: parseInt(style.width, 10),
//       height: parseInt(style.height, 10),
//     })),
//     position: image.attributes.position,
//   }
// }

const filterIncludedPayoutItems = (
  lineItemData: RelationType | RelationType[],
  included: any[],
) => {
  return included?.filter(
    (e) =>
      e.type === 'payout_item' &&
      (lineItemData as RelationType[]).find((d) => d.id === e.id) !== undefined,
  )
}

export const deserializePayout = (
  apiCart: PayoutAttr,
  included: any[],
  config: any,
): Payout => {
  const theUser = deserializeUser(
    findAttachment(included, apiCart.attributes.user_id?.toString(), 'user'),
    included,
  )
  const image = deserializeImage(
    findAttachment(
      included,
      (apiCart.relationships.image?.data as RelationType)?.id?.toString(),
      'image',
    ),
  )

  return {
    id: parseInt(apiCart.id, 10),
    number: apiCart.attributes.number,
    total: parseFloat(apiCart.attributes.total),
    payout_method_id: apiCart.attributes.payout_method_id,
    user_id: apiCart.attributes.user_id,
    created_by_id: apiCart.attributes.created_by_id,
    state: apiCart.attributes.state,
    approver_id: apiCart.attributes.approver_id,
    approver_name: apiCart.attributes.approver_name,
    is_consignment_completed: apiCart.attributes.is_consignment_completed,
    image: image,
    approved_at: getDatetimeString(apiCart.attributes.approved_at),
    paid_at: getDatetimeString(apiCart.attributes.paid_at),
    created_at: getDatetimeString(apiCart.attributes.created_at),
    updated_at: getDatetimeString(apiCart.attributes.updated_at),
    completed_at: getDatetimeString(apiCart.attributes.completed_at),
    user: theUser,
    approver: deserializeUser(
      findAttachment(
        included,
        apiCart.attributes.approver_id?.toString(),
        'user',
      ),
      null,
    ),
    payout_items:
      filterIncludedPayoutItems(
        apiCart.relationships?.payout_items?.data,
        included,
      )?.map((item, index) =>
        deserializePayoutItem(item, included, config, index),
      ) || [],
    payout_method: deserializePayoutMethod(
      findAttachment(
        included,
        (apiCart.relationships?.payout_method?.data as RelationType)?.id,
        (apiCart.relationships?.payout_method?.data as RelationType)?.type,
      ),
    ),
    remark: apiCart.attributes.remark,
    admin_remark: apiCart.attributes.admin_remark,
    adjustment: +apiCart.attributes.adjustment,
    item_total: apiCart.attributes.item_total,
    item_count: apiCart.attributes.item_count,
    pay_way_ref: apiCart.attributes.pay_way_ref,
  }
}

export const serializeCouponCode = ({
  couponCode,
}: CouponCode): CouponCodeAttr => ({
  coupon_code: couponCode,
})
