import { ApiContext, StockItem } from '../../types'
import getCurrentBearerToken from '../authentication/getCurrentBearerToken'

export default async function updateStockItem(
  { client, config }: ApiContext,
  id,
  data,
): Promise<StockItem> {
  try {
    const token = await getCurrentBearerToken({
      client,
      config,
    })

    const response = await client.stockItem.update(
      { bearerToken: token },
      id,
      { stock_item: data },
      // { payment: data },
    )
    if (response.isSuccess()) {
      const payload = response.success()
      console.log(payload.data)
      return null
    } else {
      throw response.fail()
    }
  } catch (e) {
    console.error(e)
    throw e
  }
}
