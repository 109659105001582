import { ApiContext, Cart } from '../../types'
import { deserializeCart } from '../serializers/cart'
import getCurrentBearerToken from '@/api_client/api/authentication/getCurrentBearerToken'

export default async function completeOrder(
  { client, config }: ApiContext,
  order,
): Promise<Cart> {
  try {
    const token = await getCurrentBearerToken({
      client,
      config,
    })

    const result = await client.cart.complete(
      { bearerToken: token },
      order.id,
      {
        order: {
          item_count: order.itemCount, // To confirm same number of line items
        },
        include: 'created_by,payments.payment_method,store_credits,user',
      },
    )

    if (result.isSuccess()) {
      const payload = result.success()
      const cart = deserializeCart(payload.data, payload.included, config)

      // save loadedId
      // const savedIds = localStorage.getItem('local_order_ids')?.split(',') || []
      // POSHelper.removeSavedOrderId(
      //   POSHelper.getSavedOrderIds(),
      //   cart.id.toString(),
      // )

      // if (savedIds[0] === '') {
      //   savedIds.splice(0, 1)
      // }
      // savedIds.push(cart.id.toString())
      // localStorage.setItem('local_order_ids', savedIds.join(','))

      return cart
    } else {
      // console.error('???')
      console.error(result.fail())
      // console.error(result.fail().message)

      throw result.fail()
    }
  } catch (e) {
    // console.error('!!!')
    console.error(e)
    throw e
  }
}
