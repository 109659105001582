import { ApiContext, ConsignmentLineItem } from '../../types'
import getCurrentBearerToken from '@/api_client/api/authentication/getCurrentBearerToken'

export default async function saveConsignmentItem(
  { client, config }: ApiContext,
  consignment_id,
  consignment_item_id,
  body,
): Promise<ConsignmentLineItem> {
  const bearerToken = await getCurrentBearerToken({ client, config })
  const line_item = { ...body }

  const result = await client.consignments.updateItem(
    { bearerToken },
    consignment_id,
    consignment_item_id,
    {
      include: 'variant',
    },
    { line_item },
  )

  if (result.isSuccess()) {
    const data = result.success()
    // console.log(data)
    return data
    // return deserializeConsignmentLineItem(data.data, data.included, [])
  } else {
    console.log(result.fail())
    throw result.fail()
  }
}
