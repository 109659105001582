import Messages from '@/chat_client/endpoints/Messages'
import Rooms from '@/chat_client/endpoints/Rooms'

export interface IClientConfig {
  host?: string
}

class ChatClient {
  public messages: Messages
  public rooms: Rooms

  protected host?: string

  constructor(config: IClientConfig = {}) {
    this.host = config.host || null
    this.addEndpoints()
  }

  protected addEndpoints() {
    this.messages = this.makeMessages()
    this.rooms = this.makeRooms()
  }

  protected makeMessages() {
    return new Messages(this.host)
  }

  protected makeRooms() {
    return new Rooms(this.host)
  }
}

export default ChatClient
