import { ApiContext, Consignment } from '../../types'
import getCurrentBearerToken from '@/api_client/api/authentication/getCurrentBearerToken'
import { deserializeConsignment } from '@/api_client/api/serializers/consignment'

export default async function savePayout(
  { client, config }: ApiContext,
  body = {},
  payout_id = null,
): Promise<Consignment> {
  const bearerToken = await getCurrentBearerToken({ client, config })
  let result
  const include = ''
  console.log(body)
  if (payout_id) {
    result = await client.payouts.update(
      { bearerToken },
      payout_id,
      {
        include,
      },
      { payout: { ...body, payout_items: '' } },
    )
  } else {
    result = await client.payouts.create(
      { bearerToken },
      {
        payout: body,
        include,
      },
    )
  }

  if (result.isSuccess()) {
    const data = result.success()
    // console.log(data)
    return deserializeConsignment(data.data, data.included, config)
  } else {
    console.log(result.fail())
    throw result.fail()
  }
}
