import Http from '../Http'
import {
  AccountAddressesResult,
  AccountAddressParams,
  AccountAddressResult,
  IAccountResult,
} from '../interfaces/Account'
import { ICreditCardResult, ICreditCardsResult } from '../interfaces/CreditCard'
import { IOrderResult, IOrdersResult } from '../interfaces/Order'
import { IQuery } from '../interfaces/Query'
import { IToken } from '../interfaces/Token'
import { Routes } from '../routes'

export default class Account extends Http {
  public async accountInfo(
    token: IToken,
    params: IQuery = {},
  ): Promise<IAccountResult> {
    return (await this.spreeResponse(
      'get',
      Routes.accountPath(),
      token,
      params,
    )) as IAccountResult
  }

  public async creditCardsList(
    token: IToken,
    params: IQuery = {},
  ): Promise<ICreditCardsResult> {
    return (await this.spreeResponse(
      'get',
      Routes.accountCreditCardsPath(),
      token,
      params,
    )) as ICreditCardsResult
  }

  public async defaultCreditCard(
    token: IToken,
    params: IQuery = {},
  ): Promise<ICreditCardResult> {
    return (await this.spreeResponse(
      'get',
      Routes.accountDefaultCreditCardPath(),
      token,
      params,
    )) as ICreditCardResult
  }

  public async completedOrdersList(
    token: IToken,
    params: IQuery = {},
  ): Promise<IOrdersResult> {
    return (await this.spreeResponse(
      'get',
      Routes.ordersPath(),
      token,
      params,
    )) as IOrdersResult
  }

  public async returnOrderItem(
    token: IToken,
    line_item_id,
  ): Promise<IOrdersResult> {
    return await this.spreeResponse(
      'get',
      Routes.returnOrderItemPath(line_item_id),
      token,
    )
  }

  public async shipOrderItem(
    token: IToken,
    line_item_id,
  ): Promise<IOrdersResult> {
    return await this.spreeResponse(
      'get',
      Routes.shipOrderItemPath(line_item_id),
      token,
    )
  }

  public async completedOrder(
    token: IToken,
    orderNumber: string,
    params: IQuery = {},
  ): Promise<IOrderResult> {
    return (await this.spreeResponse(
      'get',
      Routes.accountCompletedOrderPath(orderNumber),
      token,
      params,
    )) as IOrderResult
  }

  public async create(token: IToken, params: IQuery): Promise<IAccountResult> {
    return (await this.spreeResponse(
      'post',
      Routes.usersPath(),
      token,
      params,
    )) as IAccountResult
  }

  public async update(
    token: IToken,
    user_id,
    params: IQuery,
  ): Promise<IAccountResult> {
    return (await this.spreeResponse(
      'patch',
      Routes.userPath(user_id),
      token,
      params,
    )) as IAccountResult
  }

  public async getAddresses(
    token: IToken,
    params: IQuery,
  ): Promise<AccountAddressesResult> {
    return (await this.spreeResponse(
      'get',
      Routes.addressesPath(),
      token,
      params,
    )) as AccountAddressesResult
  }

  public async createAddress(
    token: IToken,
    params: AccountAddressParams,
  ): Promise<AccountAddressResult> {
    return (await this.spreeResponse(
      'post',
      Routes.accountAddressesPath(),
      token,
      params,
    )) as AccountAddressResult
  }

  public async updateAddress(
    token: IToken,
    addressId: string,
    params: AccountAddressParams,
  ): Promise<AccountAddressResult> {
    return (await this.spreeResponse(
      'patch',
      Routes.addressPath(addressId),
      token,
      params,
    )) as AccountAddressResult
  }

  public async deleteAddress(
    token: IToken,
    addressId: string,
  ): Promise<AccountAddressResult> {
    return (await this.spreeResponse(
      'delete',
      Routes.addressPath(addressId),
      token,
    )) as AccountAddressResult
  }
}
