import { ApiContext } from '../../types'
import getCurrentBearerToken from '@/api_client/api/authentication/getCurrentBearerToken'

export default async function deleteAddress(
  { client, config }: ApiContext,
  addressId,
): Promise<boolean> {
  try {
    const token = await getCurrentBearerToken({
      client,
      config,
    })

    const result = await client.account.deleteAddress(
      { bearerToken: token },
      addressId,
    )

    if (result.isSuccess()) {
      return addressId
    } else {
      throw result.fail()
    }
  } catch (e) {
    console.error(e)
    throw e
  }
}
