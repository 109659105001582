import { ApiContext } from '../../types'
import getCurrentBearerToken from '@/api_client/api/authentication/getCurrentBearerToken'

export default async function deletePayoutItem(
  { client, config }: ApiContext,
  payoutId,
  itemId,
): Promise<boolean> {
  try {
    const token = await getCurrentBearerToken({
      client,
      config,
    })

    const result = await client.payouts.removeItem(
      { bearerToken: token },
      payoutId,
      itemId,
    )

    if (result.isSuccess()) {
      return true
    } else {
      throw result.fail()
    }
  } catch (e) {
    console.error(e)
    throw e
  }
}
