import { ApiContext } from '../../types'
import getCurrentBearerToken from '../authentication/getCurrentBearerToken'

export default async function getShipmentFee(
  { client, config }: ApiContext,
  address,
) {
  const bearerToken = await getCurrentBearerToken({ client, config })

  const response = await client.delivery.fee({ bearerToken }, { address })
  if (response.isSuccess()) {
    return response.success()
  } else {
    return null
  }
}
