import Http from '../Http'
import { IQuery } from '../interfaces/Query'
import { IToken } from '../interfaces/Token'
import { Routes } from '../routes'

export default class Attendances extends Http {
  public async show(token: IToken, params: IQuery = {}): Promise<any> {
    return (await this.spreeResponse(
      'get',
      Routes.attendancesPath(),
      token,
      params,
    )) as any
  }

  public async update(
    token: IToken,
    id,
    data,
    params: IQuery = {},
  ): Promise<any> {
    return (await this.spreeResponse(
      'put',
      Routes.attendancePath(id),
      token,
      params,
      data,
    )) as any
  }

  public async create(token: IToken, params: IQuery = {}, data): Promise<any> {
    return (await this.spreeResponse(
      'post',
      Routes.attendancesPath(),
      token,
      params,
      data,
    )) as any
  }

  public async delete(token: IToken, id): Promise<any> {
    return (await this.spreeResponse(
      'delete',
      Routes.attendancePath(id),
      token,
    )) as any
  }
}
