import { ApiContext, LineItem } from '../../types'
import { deserializeLineItem } from '../serializers/cart'
import getCurrentBearerToken from '@/api_client/api/authentication/getCurrentBearerToken'

// old
export default async function removeLineItem(
  { client, config }: ApiContext,
  orderId,
  line_item_id,
): Promise<LineItem> {
  try {
    const token = await getCurrentBearerToken({
      client,
      config,
    })

    const result = await client.cart.removeLineItem(
      { bearerToken: token },
      orderId,
      line_item_id,
      { include: 'variant,variant.images,order' },
      {
        // order_id: orderId,
        // quantity: 1,
      },
    )

    if (result.isSuccess()) {
      const payload = result.success()
      console.log(payload)
      const lineItem = deserializeLineItem(
        payload.data,
        payload.included,
        config,
      )
      return lineItem
    } else {
      throw result.fail()
    }
  } catch (e) {
    console.error(e)
    throw e
  }
}
