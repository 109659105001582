import type { ApiContext } from '../../types'
import { deserializeProduct } from '../serializers/product'
import getCurrentBearerToken from '@/api_client/api/authentication/getCurrentBearerToken'
import { addHostToProductImages } from '@/api_client/api/serializers/image'

export default async function getProduct(
  { client, config }: ApiContext,
  productId,
) {
  try {
    const token = await getCurrentBearerToken({ client, config })
    const result = await client.products.show(
      { bearerToken: token },
      productId,
      {
        use_sku: true,
        fields: {
          //   product:
          //     'name,slug,sku,description,primary_variant,default_variant,variants,option_types,taxons,added_to_cart_at,price,cost_price,compare_at_price',
          // default_variant:
          //   'sku,price,cost_price,display_price,in_stock,product,images,option_values,is_master',
        },
        include:
          'default_variant,default_variant.stock_items,taxons,product_properties,properties,images,discounts',
      },
    )

    if (result.isSuccess()) {
      const data = result.success()
      console.log(data)
      const productsData = addHostToProductImages(data, config)
      const r = deserializeProduct(productsData)
      // console.log(r)
      return r
    } else {
      throw result.fail()
    }
  } catch (e) {
    console.error(e)
    throw e
  }
}
