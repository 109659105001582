import Axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  Method,
} from 'axios'
import * as qs from 'qs'
// import Result from './helpers/Result'
// import { ErrorClass } from './interfaces/errors/ErrorClass'
// import { JsonApiResponse } from './interfaces/JsonApi'
// import { ResultResponse } from './interfaces/ResultResponse'
// import { IOAuthTokenResult, IToken } from './interfaces/Token'
import { AuthHelper } from '@/api_client/index'
import { Routes } from '@/api_client/routes'
import { refreshParams } from '@/api_client/helpers/auth'
import ResponseError from '@/chat_client/errors/NoResponseError'

export default class Http {
  public host: string
  public axios: AxiosInstance

  constructor(host?: string) {
    this.host = host || process.env.SPREE_HOST || 'http://localhost:3000/'

    this.axios = Axios.create({
      baseURL: this.host,
      headers: {
        'Content-Type': 'application/json',
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      },
    })
  }

  protected async chatResponse(
    method: Method,
    url: string,
    token: string,
    params: any = {},
    data = null,
    isShowErrorAlert = true,
    responseType = null,
    refreshToken = true,
  ): Promise<any> {
    try {
      const headers = this.headers(token)

      const axiosConfig: AxiosRequestConfig = {
        url,
        params,
        method,
        headers,
        data,
        responseType,
      }

      const response = await this.axios(axiosConfig)

      return response.data
    } catch (error) {
      console.log('error')
      console.log(error)
      console.log(error.response)
      // console.log(error.response.status)

      if (error.response.status == 401) {
        console.log('error.response.status == 401')
      } else if (error.response.status == 404) {
        console.error('404')
        console.error(error.response.data.error)
      } else {
        if (isShowErrorAlert) {
          alert(error.response.data.error)
        }
      }
      console.log(error.response.data.error)
      return new ResponseError()
    }
  }

  private headers(token) {
    const header = {}

    // if (tokens.orderToken) {
    //   header['X-Spree-Order-Token'] = tokens.orderToken
    // }

    // if (tokens.bearerToken) {
    header['Authorization'] = `Bearer ${token}`
    // }
    // no cache for backend control
    header['Cache-Control'] = 'no-cache, no-store'
    header['Pragma'] = 'no-cache'
    header['Expires'] = 'Mon, 01 Jan 1990 00:00:00 GMT'

    return header
  }
}
