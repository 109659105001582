import { ApiContext } from '@/chat_client/ApiChatClient'
import getCurrentBearerToken from '@/chat_client/api/authentication/getCurrentBearerToken'
import { JsonApiListResponse } from '@/chat_client/interfaces/JsonApi'
import { Message } from '@/chat_client/serializers/message'

export default async function getMessages(
  { client, config }: ApiContext,
  params = {},
): Promise<JsonApiListResponse<Message>> {
  const token = await getCurrentBearerToken({ client, config })

  const response = await client.messages.list(token, params)
  return response
}
