import { ApiContext } from '../../types'
import getCurrentBearerToken from '../authentication/getCurrentBearerToken'
import { deserializeTaxon } from '@/api_client/api/serializers/taxon'

export default async function getTaxon(
  { client, config }: ApiContext,
  taxonId = '',
) {
  const bearerToken = await getCurrentBearerToken({ client, config })

  const response = await client.taxons.show({ bearerToken }, taxonId, {
    include: 'children.children',
  })
  if (response.isSuccess()) {
    const payload = response.success()
    // console.log(payload)
    return deserializeTaxon(payload.data, payload.included)
  } else {
    console.log(response.fail())
    return null
  }
}
