import { ApiContext } from '@/api_client/types'
import Client from '@/api_client/Client'
import { AuthHelper, makeClient } from '@/api_client/index'
import logIn from '@/api_client/api/logIn'
import getUsers from '@/api_client/api/getUsers'
import getRoles from '@/api_client/api/getRoles'
import getAreas from '@/api_client/api/getAreas'
import getAvailableCountries from '@/api_client/api/getCountryDetails'
import getOrders from '@/api_client/api/getOrders'
import getConsignments from '@/api_client/api/getConsignments'
import getProducts from '@/api_client/api/getProducts'
import getProduct from '@/api_client/api/getProduct'
import saveUser from '@/api_client/api/saveUser'
import getOrder from '@/api_client/api/getOrder'
import createOrder from '@/api_client/api/createOrder'
import updateOrder from '@/api_client/api/updateOrder'
import deleteOrder from '@/api_client/api/deleteOrder'
import removeFromOrder from '@/api_client/api/removeFromOrder'
import getPaymentMethods from '@/api_client/api/getPaymentMethods'
import getPromotions from '@/api_client/api/getPromotions'
import getShipments from '@/api_client/api/getShipments'
import setOrderShippingMethod from '@/api_client/api/setOrderShippingMethod'
import getFreeShipAmount from '@/api_client/api/getFreeShipAmount'
import completeOrder from '@/api_client/api/completeOrder'
import getConsignment from '@/api_client/api/getConsignment'
import saveConsignment from '@/api_client/api/saveConsignment'
import addItemsToConsignment from '@/api_client/api/addItemsToConsignment'
import getTaxons from '@/api_client/api/getTaxons'
import getTaxon from '@/api_client/api/getTaxon'
import saveConsignmentItem from '@/api_client/api/saveConsignmentItem'
import deleteConsignmentItem from '@/api_client/api/deleteConsignmentItem'
import addImage from '@/api_client/api/addImage'
import deleteImage from '@/api_client/api/deleteImage'
import updateImage from '@/api_client/api/updateImage'
import applyCouponCode from '@/api_client/api/applyCouponCode'
import { IQuery } from '@/api_client/interfaces/Query'
import saveProduct from '@/api_client/api/saveProduct'
import adjustProductStock from '@/api_client/api/adjustProductStock'
import removeCoupon from '@/api_client/api/deleteCoupon'
import addLineItem from '@/api_client/api/addLineItem'
import createAdjustment from '@/api_client/api/createAdjustment'
import removeAdjustment from '@/api_client/api/removeAdjustment'
import removeLineItem from '@/api_client/api/removeLineItem'
import getPayoutMethods from '@/api_client/api/getPayoutMethods'
import savePayoutMethod from '@/api_client/api/savePayoutMethod'
import deletePayoutMethod from '@/api_client/api/deletePayoutMethod'
import returnOrderItem from '@/api_client/api/returnOrderItem'
import shipOrderItem from '@/api_client/api/shipOrderItem'
import getUser from '@/api_client/api/getUser'
import getPayouts from '@/api_client/api/getPayouts'
import getPayout from '@/api_client/api/getPayout'
import refreshPayoutItems from '@/api_client/api/refreshPayoutItems'
import getConsignmentsForPayout from '@/api_client/api/getConsignmentsForPayout'
import updatePayoutState from '@/api_client/api/updatePayoutState'
import savePayout from '@/api_client/api/savePayout'
import deletePayout from '@/api_client/api/deletePayout'
import deletePayoutItem from '@/api_client/api/deletePayoutItem'
import getCartMetaData from '@/api_client/api/getCartMetaData'
import addPayoutItem from '@/api_client/api/addPayoutItem'
import removeAllPayoutItems from '@/api_client/api/removeAllPayoutItems'
import getSaleReport from '@/api_client/api/getSaleReport'
import getSaleOldReport from '@/api_client/api/getSale1Report'
import getShipmentFee from '@/api_client/api/getShipmentFee'
import addTaxon from '@/api_client/api/addTaxon'
import updateShipmentTrackNumber from '@/api_client/api/updateShipmentNumber'
import setShipmentShip from '@/api_client/api/setShipmentShip'
import addProductDiscount from '@/api_client/api/addProductDiscount'
import deleteProductDiscount from '@/api_client/api/deleteProductDiscount'
import discountProductDirect from '@/api_client/api/discountProductDirect'
import getProductFbDes from '@/api_client/api/getProductFbDes'
import downloadTextedPhotos from '@/api_client/api/downloadTextedPhotos'
import getProductShortDes from '@/api_client/api/getProductShortDes'
import returnOrder from '@/api_client/api/returnOrder'
import updatePayment from '@/api_client/api/updatePayment'
import resetCLineItemCommission from '@/api_client/api/resetCLineItemCommission'
import updatePaymentState from '@/api_client/api/updatePaymentState'
import placeShipmentDelivery from '@/api_client/api/placeShipmentDelivery'
import getConsignmentLineItems from '@/api_client/api/getConsignmentLineItems'
import getLogEntries from '@/api_client/api/getLogEntries'
import addPayoutItemsByConsignmentId from '@/api_client/api/addPayoutItemsByConsignmentId'
import getStockLocations from '@/api_client/api/getStockLocations'
import updateStockItem from '@/api_client/api/updateStockItem'
import getStoreCredits from '@/api_client/api/getStoreCredits'
import addPayment from '@/api_client/api/addPayment'
import deleteConsignment from '@/api_client/api/deleteConsignment'
import discountAllConsignmentItems from '@/api_client/api/discountAllConsignmentItems'
import getConsignmentReport from '@/api_client/api/getConsignmentReport'
import getCmsPage from '@/api_client/api/getCmsPage'
import getCmsPages from '@/api_client/api/getCmsPages'
import addCmsPage from '@/api_client/api/addCmsPage'
import updateCmsPage from '@/api_client/api/updateCmsPage'
import getImages from '@/api_client/api/getImages'
import getStockLocationAreas from '@/api_client/api/getStockLocationAreas'
import clearStockLocationArea from '@/api_client/api/clearStockLocationArea'
import getShiftTable from '@/api_client/api/getShiftTable'
import getAttendanceLogs from '@/api_client/api/getAttendanceLogs'
import addAttendanceLog from '@/api_client/api/addAttendanceLog'
import getProductReport from '@/api_client/api/getProductsReport'
import getAttendances from '@/api_client/api/getAttendances'
import addAttendance from '@/api_client/api/addAttendance'
import updateAttendance from '@/api_client/api/updateAttendance'
import deleteAttendance from '@/api_client/api/deleteAttendance'
import connectProductConsignmentLineItem from '@/api_client/api/connectProductConsignmentLineItem'
import addAddress from '@/api_client/api/addAddress'
import updateAddress from '@/api_client/api/updateAddress'
import getAddresses from '@/api_client/api/getAddresses'
import deleteAddress from '@/api_client/api/deleteAddress'
import updateTaxonProductCount from '@/api_client/api/updateTaxonProductCount'
import getPerformancesReport from './api/getPerformancesReport'
import addLogEntry from '@/api_client/api/addLogEntry'
import addLogMultiProducts from './api/addLogMultiProducts'
import firebase from 'firebase/compat'
import Auth = firebase.auth.Auth
import cancelOrder from './api/cancelOrder'
import deleteProduct from '@/api_client/api/deleteProduct'

class ScClient {
  // public backendPath =
  //   process.env.NODE_ENV === 'production'
  //     ? 'http://171.244.33.6:4000/'
  //     : 'http://localhost:4000/'
  //
  public backendPath = 'http://171.244.33.6:4000/'

  public client: Client
  public config: any
  private apiContext: ApiContext
  // private allowedUserIds = ['1', '51', '166', '9090']
  public isAllowedIPAccess = true

  constructor(isHttps = true) {
    const theBackendPath = isHttps
      ? 'https://b.secondcorner.vn/'
      : this.backendPath
    this.client = makeClient({
      host: theBackendPath,
    })
    this.config = { auth: new AuthHelper(), backendUrl: theBackendPath }
    this.apiContext = { client: this.client, config: this.config }
  }

  // protected async checkAuth() {
  //   return (await getRoles(this.apiContext)) != null
  // }

  protected getRoles() {
    return getRoles(this.apiContext)
  }

  protected getLogEntries(source_type: string, source_id: string) {
    return getLogEntries(this.apiContext, source_type, source_id)
  }

  protected getSaleReport(start_date, end_date) {
    return getSaleReport(this.apiContext, start_date, end_date)
  }

  protected getPerformancesReport(start_date, end_date) {
    return getPerformancesReport(this.apiContext, start_date, end_date)
  }

  protected getProductReport(start_date, end_date) {
    return getProductReport(this.apiContext, start_date, end_date)
  }

  protected getSaleOldReport(start_date, end_date) {
    return getSaleOldReport(this.apiContext, start_date, end_date)
  }

  protected getConsignmentReport(start_date, end_date) {
    return getConsignmentReport(this.apiContext, start_date, end_date)
  }

  protected addCmsPage(cmsPage) {
    return addCmsPage(this.apiContext, cmsPage)
  }

  protected updateCmsPage(cmsPageId, cmsPage) {
    return updateCmsPage(this.apiContext, cmsPageId, cmsPage)
  }

  protected getCmsPages(params = {}) {
    return getCmsPages(this.apiContext, params)
  }

  protected getCmsPage(id, params = {}) {
    return getCmsPage(this.apiContext, id, params)
  }

  protected logIn(username, password) {
    return logIn(this.apiContext, { username, password })
  }

  protected logout() {
    return AuthHelper.removeOAuthToken()
  }

  protected getAttendances(params: IQuery = {}) {
    return getAttendances(this.apiContext, params)
  }

  protected addAttendance(data) {
    return addAttendance(this.apiContext, data)
  }

  protected updateAttendance(id, data) {
    return updateAttendance(this.apiContext, id, data)
  }

  protected deleteAttendance(id) {
    return deleteAttendance(this.apiContext, id)
  }

  protected getShiftTable(shift_date_from, shift_date_to, user_id = null) {
    return getShiftTable(
      this.apiContext,
      shift_date_from,
      shift_date_to,
      user_id,
    )
  }

  protected getAttendanceLogs(params) {
    return getAttendanceLogs(this.apiContext, params)
  }

  protected addProductLogEntry(variant_id: string, details: string) {
    return addLogEntry(this.apiContext, 'Spree::Variant', variant_id, details)
  }

  protected addLogEntry(
    source_type: string,
    source_id: string,
    details: string,
  ) {
    return addLogEntry(this.apiContext, source_type, source_id, details)
  }

  protected addLogMultiProducts(ids: [], details: string) {
    return addLogMultiProducts(this.apiContext, ids, details)
  }

  protected addAttendanceLog(record_type, user_id, attendance_at) {
    return addAttendanceLog(this.apiContext, {
      record_type,
      user_id,
      attendance_at,
      attendance_location: 'HCM',
      attendance_latitude: '0',
      attendance_longitude: '0',
    })
  }

  protected getCustomers(params: IQuery = {}, extraInclude = '') {
    const filter = { ...params.filter }
    let sort = null
    if (filter.year_value_key === 'lifetime') {
      sort = '-lifetime_value'
    } else if (filter.year_value_key === 'current') {
      sort = '-current_year_value'
    }
    return getUsers(
      this.apiContext,
      {
        ...params,
        filter: { ...params.filter },
        sort,
      },
      extraInclude,
    )
  }

  protected isCustomerExistByPhone(phone) {
    return getUsers(this.apiContext, {
      filter: { phone_cont: phone },
    })
  }

  protected getStaffs(params: IQuery = {}, role = 'admin') {
    // console.log(params.filter.locked_at_not_null)
    return getUsers(
      this.apiContext,
      {
        ...params,
        filter: {
          roles_in: role,
          ...params.filter,
          locked_at_not_null:
            params.filter == null ||
            params.filter.locked_at_not_null == undefined
              ? 'false'
              : params.filter.locked_at_not_null,
        },
      },
      ',spree_roles',
    )
  }

  protected getCustomer(id) {
    return getUser(this.apiContext, id)
  }

  protected getCustomerPayoutMethods(id) {
    return getPayoutMethods(this.apiContext, id)
  }

  protected saveCustomerPayoutMethods(
    id,
    payment_method,
    payout_method_id = null,
  ) {
    return savePayoutMethod(
      this.apiContext,
      id,
      payment_method,
      payout_method_id,
    )
  }

  protected deleteCustomerPayoutMethods(id, payout_method_id) {
    return deletePayoutMethod(this.apiContext, id, payout_method_id)
  }

  protected getAreas({ parentArea }) {
    return getAreas(this.apiContext, { parentArea })
  }

  protected getCountryDetails() {
    return getAvailableCountries(this.apiContext, { iso: 'VN' })
  }

  protected getUserCompletedOrders(user_id: number, params = {}) {
    return getOrders(this.apiContext, {
      ...params,
      filter: { user_id_eq: user_id, state_eq: 'complete' },
    })
  }

  protected applyCouponCode(orderId, coupon_code) {
    return applyCouponCode(this.apiContext, orderId, coupon_code)
  }

  protected removeCoupon(orderId, promotion_id) {
    return removeCoupon(this.apiContext, orderId, promotion_id)
  }

  protected getOrders(params: IQuery = {}, is_empty_include = false) {
    return getOrders(
      this.apiContext,
      {
        ...params,
        filter: { ...params.filter },
      },
      is_empty_include,
    )
  }

  protected getCompletedOrders(params: IQuery = {}) {
    return getOrders(this.apiContext, {
      ...params,
      filter: { ...params.filter, state_eq: 'complete' },
    })
  }

  protected returnOrderItem(lineItemId) {
    return returnOrderItem(this.apiContext, lineItemId)
  }

  protected shipOrderItem(lineItemId) {
    return shipOrderItem(this.apiContext, lineItemId)
  }

  protected getUserConsignments(user_id: number, params = {}) {
    return getConsignments(this.apiContext, {
      ...params,
      filter: { user_id_eq: user_id },
    })
  }

  protected getConsignmentByProductId(product_id: number) {
    return getConsignment(this.apiContext, product_id, true)
  }

  protected getConsignments(params: IQuery = {}) {
    return getConsignments(this.apiContext, {
      ...params,
      filter: { ...params.filter },
    })
  }

  protected discountAllConsignmentItems(consignmentId, discount) {
    return discountAllConsignmentItems(this.apiContext, consignmentId, discount)
  }

  protected getConsignmentsForPayout(user_id, params: IQuery = {}) {
    return getConsignmentsForPayout(
      this.apiContext,
      {
        ...params,
        filter: { ...params.filter },
      },
      user_id,
    )
  }

  protected getConsignmentLineItems(
    consignmentId,
    consignmentNumber,
    useNumber = false,
    consignmentNumbers = '',
    in_stock = false,
  ) {
    return getConsignmentLineItems(
      this.apiContext,
      consignmentId,
      consignmentNumber,
      useNumber,
      consignmentNumbers,
      in_stock,
    )
  }

  protected getConsignment(consignmentId) {
    return getConsignment(this.apiContext, consignmentId)
  }

  protected getProducts(params, extraInclude = '') {
    return getProducts(this.apiContext, { ...params }, { extraInclude })
  }

  protected getProductsByConsignment(consignmentId) {
    return getProducts(
      this.apiContext,
      { filter: { consignment_id: consignmentId }, per_page: 100, sort: '-id' },
      { showTaxons: false, showStockItems: true },
    )
  }

  protected getProductsSimple(params, keyword) {
    return getProducts(
      this.apiContext,
      { ...params, sort: '-id' },
      { keyword, showTaxons: false, showStockItems: false },
    )
  }

  protected getProduct(productId) {
    return getProduct(this.apiContext, productId)
  }

  protected adjustProductStock(
    variantId,
    stock_location_id,
    stock_location_area_id,
    quantity,
  ) {
    return adjustProductStock(
      this.apiContext,
      variantId,
      stock_location_id,
      stock_location_area_id,
      quantity,
    )
  }

  protected saveProductMeasurement(product, productId) {
    const product_properties_attributes = []
    // console.log(product.measurements)
    for (const m in product.measurements) {
      // console.log(product.measurements[m])
      // console.log(product.measurements[m].id)
      if (
        product.measurements[m].value != null &&
        product.measurements[m].value != '0'
      ) {
        product_properties_attributes.push(product.measurements[m])
      }
    }
    // console.log(product_properties_attributes)
    //
    return saveProduct(
      this.apiContext,
      {
        product: {
          // ...product,
          product_properties_attributes,
        },
      },
      productId,
      false,
    )
  }

  protected saveProduct(product, productId = null) {
    const product_properties_attributes = []
    // console.log(product.measurements)
    for (const m in product.measurements) {
      // console.log(product.measurements[m])
      // console.log(product.measurements[m].id)
      if (
        product.measurements[m].value != null &&
        product.measurements[m].value != '0'
      ) {
        product_properties_attributes.push(product.measurements[m])
      }
    }
    // console.log(product_properties_attributes)
    //
    return saveProduct(
      this.apiContext,
      {
        product: {
          ...product,
          images: [],
          product_properties_attributes,
        },
      },
      productId,
    )
  }

  protected connectProductConsignmentLineItem(productId) {
    return connectProductConsignmentLineItem(this.apiContext, productId)
  }

  protected createUser(params = {}) {
    return saveUser(this.apiContext, params)
  }

  protected updateUser(user_id, params = {}) {
    return saveUser(this.apiContext, params, user_id)
  }

  protected updateUserPassword(user_id, password) {
    return saveUser(
      this.apiContext,
      { password, password_confirmation: password },
      user_id,
    )
  }

  protected updateUserEmail(user_id, email) {
    return saveUser(this.apiContext, { email, update_email: '1' }, user_id)
  }

  protected updateUserRoles(user_id, spree_role_ids) {
    return saveUser(this.apiContext, { spree_role_ids }, user_id)
  }

  protected updateUserEmployment(user_id, private_metadata) {
    return saveUser(this.apiContext, { private_metadata }, user_id)
  }

  protected returnOrder(id) {
    return returnOrder(this.apiContext, id)
  }

  protected cancelOrder(id) {
    return cancelOrder(this.apiContext, id)
  }

  protected getOrder(id) {
    return getOrder(this.apiContext, id, {})
  }

  protected getOrderByNumber(id, isIncludeInventory = false) {
    return getOrder(this.apiContext, id, {
      isUseNumber: true,
      isIncludeInventory,
    })
  }

  protected getOrderByProductId(id) {
    return getOrder(this.apiContext, id, { isUseVariantId: true })
  }

  protected deleteOrder(id) {
    return deleteOrder(this.apiContext, id)
  }

  protected updateShipmentTrackNumber(id, number) {
    return updateShipmentTrackNumber(this.apiContext, id, number)
  }

  protected setShipmentShip(id, isPlaceDelivery = false) {
    return setShipmentShip(this.apiContext, id, isPlaceDelivery)
  }

  protected placeShipmentDelivery(id) {
    return placeShipmentDelivery(this.apiContext, id)
  }

  protected createOrder(order) {
    return createOrder(this.apiContext, {
      order: {
        ...order,
        private_metadata: {
          shipping_method_id: order?.temp_shipping_method_id,
          payment_method_id: order?.temp_payment_method_id,
          estimated_shipping_fee: order?.estimated_shipping_fee,
          shipper_code: order?.shipper_code,
          payments: order?.temp_payments,
          is_free_ship: order?.is_free_ship,
          is_apply_credit: order?.is_apply_credit,
        },
      },
    })
  }

  protected _updateOrder(orderId, params) {
    return updateOrder(this.apiContext, orderId, params)
  }

  protected resetCLineItemCommission(cLineItemId) {
    return resetCLineItemCommission(this.apiContext, cLineItemId)
  }

  // protected updateOrderPaymentMethod(orderId, params) {
  //   return updateOrder(this.apiContext, orderId, params)
  // }

  protected updateOrderUser(orderId, params: any, isIncludeAddress = true) {
    const addressAttributes = isIncludeAddress
      ? this.genAddressAtrs(params.address)
      : null

    return updateOrder(this.apiContext, orderId, {
      order: {
        is_force: params.is_force,
        user_id: params.user_id,
        email: params.email,
        ship_address_attributes: addressAttributes,
        bill_address_attributes: addressAttributes,
        // bill_address_id,
        // ship_address_id,
      },
    })
  }

  private genAddressAtrs(address) {
    let addressAttributes = {
      ...address,
      id: null,
      firstname: address?.firstname || address?.name,
      lastname: address?.firstname || address?.name,
      state: address?.state_name || address?.state_name,
      billing: null,
      shipping: null,
      country_id: 232, // fucking Important!!!
    }
    if (
      (!addressAttributes.address1 ||
        addressAttributes.address1.length === 0) &&
      addressAttributes?.state_name === null
    ) {
      addressAttributes = null
    }
    return addressAttributes
  }

  protected updateOrderAddress(orderId, address: any) {
    const addressAttributes = this.genAddressAtrs(address)
    if (addressAttributes == null) {
      return
    }
    // if (address.id) {
    //   return updateAddress(this.apiContext, address.id, addressAttributes)
    // } else {
    return updateOrder(
      this.apiContext,
      orderId,
      {
        order: {
          ship_address_attributes: addressAttributes,
          bill_address_attributes: addressAttributes,
        },
      },
      false,
    )
    // }
  }

  protected updateOrderAddressAfterComplete(
    orderId,
    address: any,
    shipment_total,
  ) {
    const addressAttributes = this.genAddressAtrs(address)
    if (addressAttributes == null) {
      return
    }
    return updateOrder(this.apiContext, orderId, {
      order: {
        is_force: true,
        is_update_shipment_total: true,
        ship_address_attributes: addressAttributes,
        bill_address_attributes: addressAttributes,
        shipment_total: shipment_total,
      },
    })
    // }
  }

  protected updateOrderDeliveryFee(orderId, shipment_total) {
    return updateOrder(this.apiContext, orderId, {
      order: {
        is_force: true,
        is_update_shipment_total: true,
        shipment_total: shipment_total,
      },
    })
    // }
  }

  protected updateOrderChannel(orderId, channel, is_force = false) {
    return updateOrder(this.apiContext, orderId, {
      order: { is_force: is_force, channel: channel },
    })
  }

  protected updateCashier(orderId, approver_id, is_force = false) {
    return updateOrder(this.apiContext, orderId, {
      order: { is_force: is_force, approver_id: approver_id },
    })
  }

  protected updateShipmentState(orderId, shipment_state) {
    return updateOrder(this.apiContext, orderId, {
      order: { is_force: true, shipment_state: shipment_state },
    })
  }

  protected updateOrderNote(orderId, note) {
    return updateOrder(this.apiContext, orderId, {
      order: { public_metadata: { note } },
    })
  }

  protected completeOrder(order) {
    return completeOrder(this.apiContext, order)
  }

  protected updateOrderPrivateMetadata(order) {
    // console.log('updateOrderShippingPaymentMethod')
    return updateOrder(this.apiContext, order.id, {
      order: {
        private_metadata: {
          shipping_method_id: order.temp_shipping_method_id,
          payment_method_id: order.temp_payment_method_id,
          estimated_shipping_fee: order.estimated_shipping_fee,
          shipper_code: order.shipper_code,
          is_free_ship: order.is_free_ship,
          customer_pay: order.customer_pay,
          deposit: order.deposit,
          payments: order.temp_payments,
          deposit_payment_method_id: order.deposit_payment_method_id,
          is_apply_credit: order.is_apply_credit,
        },
      },
    })
  }

  // protected updateOrderShipment(order) {
  //   // console.log('updateOrderShippingPaymentMethod')
  //   return updateOrder(this.apiContext, order.id, {
  //     order: {
  //       // is_update_shipment: '1', // update after order placed
  //       private_metadata: {
  //         shipping_method_id: order.temp_shipping_method_id,
  //         payment_method_id: order.temp_payment_method_id,
  //         estimated_shipping_fee: order.estimated_shipping_fee,
  //         is_free_ship: order.is_free_ship,
  //         customer_pay: order.customer_pay,
  //       },
  //     },
  //   })
  // }

  protected updatePaymentPaymentMethod(paymentId, paymentMethodId) {
    // console.log('updateOrderShippingPaymentMethod')
    return updatePayment(this.apiContext, paymentId, {
      payment_method_id: paymentMethodId,
    })
  }

  protected updatePaymentConfirmation(paymentId, checked) {
    // console.log('updateOrderShippingPaymentMethod')
    return updatePayment(this.apiContext, paymentId, {
      private_metadata: { payment_confirm: checked },
    })
  }

  protected updatePaymentState(paymentId, state) {
    // console.log('updateOrderShippingPaymentMethod')
    return updatePaymentState(this.apiContext, paymentId, state)
  }

  protected addPayment(
    orderId,
    paymentMethodId,
    amount,
    source_type = null,
    source_id = null,
  ) {
    return addPayment(this.apiContext, orderId, {
      paymentMethodId,
      amount,
      source_type,
      source_id,
    })
  }

  // protected addProductToOrder(orderId, variant_id) {
  //   return addToOrder(this.apiContext, orderId, variant_id)
  // }

  protected addLineItem(orderId, variant_id) {
    return addLineItem(this.apiContext, orderId, variant_id)
  }

  protected addLineItemBySku(orderId, sku) {
    return addLineItem(this.apiContext, orderId, '', sku)
  }

  protected createAdjustment(orderId, data) {
    return createAdjustment(this.apiContext, orderId, data)
  }

  protected removeAdjustment(adjustmentId) {
    return removeAdjustment(this.apiContext, adjustmentId)
  }

  protected removeLineItem(orderId, variant_id) {
    return removeLineItem(this.apiContext, orderId, variant_id)
  }

  protected removeProductFromOrder(orderId, itemLineId) {
    return removeFromOrder(this.apiContext, orderId, itemLineId)
  }

  protected getPaymentMethods(isShowStoreCredit) {
    return getPaymentMethods(this.apiContext, isShowStoreCredit)
  }

  protected getPromotions() {
    return getPromotions(this.apiContext)
  }

  protected getShipments() {
    return getShipments(this.apiContext)
  }

  protected getCartMetaData() {
    return getCartMetaData(this.apiContext)
  }

  protected getFreeShipAmount() {
    return getFreeShipAmount(this.apiContext)
  }

  protected addImage(variantId, image, viewable_type = 'Spree::Variant') {
    return addImage(this.apiContext, variantId, image, viewable_type)
  }

  protected deleteImage(imageId) {
    return deleteImage(this.apiContext, imageId)
  }

  protected changeProductImagePosition(imageId, params) {
    return updateImage(this.apiContext, imageId, params)
  }

  protected setOrderShippingMethod(orderId, shippingMethodId) {
    return setOrderShippingMethod(this.apiContext, orderId, shippingMethodId)
  }

  protected async getShipmentFee(address) {
    return getShipmentFee(this.apiContext, address)
  }

  protected getAddresses(userId) {
    return getAddresses(this.apiContext, { filter: { user_id_eq: userId } })
  }

  protected addAddress(address) {
    return addAddress(this.apiContext, address)
  }

  protected updateAddress(addressId, address) {
    return updateAddress(this.apiContext, addressId, address)
  }

  protected deleteAddress(addressId) {
    return deleteAddress(this.apiContext, addressId)
  }

  protected createConsignment(params = {}) {
    return saveConsignment(this.apiContext, params)
  }

  protected updateConsignment(consignmentId, params = {}) {
    return saveConsignment(this.apiContext, params, consignmentId)
  }

  protected addItemsToConsignment(consignmentId, quantity) {
    return addItemsToConsignment(this.apiContext, consignmentId, quantity)
  }

  protected saveConsignmentItem(consignmentId, itemId, item) {
    return saveConsignmentItem(this.apiContext, consignmentId, itemId, item)
  }

  protected deleteConsignmentItem(consignmentId, itemId) {
    return deleteConsignmentItem(this.apiContext, consignmentId, itemId)
  }

  protected deleteConsignment(consignmentId) {
    return deleteConsignment(this.apiContext, consignmentId)
  }

  protected async getTaxons(params) {
    return await getTaxons(this.apiContext, params)
  }

  protected async getParentTaxons() {
    return await getTaxons(this.apiContext, {
      filter: {
        depth_eq: '0',
      },
      per_page: 100,
    })
  }

  protected async getCommonTaxonParents() {
    const taxonsRaw = await getTaxons(this.apiContext, {
      filter: {
        permalink_in:
          'women,conditions,colors,sizes,materials,patterns,styles,discounts,sc-sizes',
        depth_eq: '0',
      },
      include: 'children.children',
      per_page: 100,
    })
    const taxons = taxonsRaw.data
    return {
      brands: [],
      categories: taxons.find((t) => t.slug === 'women').items,
      conditions: taxons.find((t) => t.slug === 'conditions').items,
      colors: taxons.find((t) => t.slug === 'colors').items,
      sizes: taxons.find((t) => t.slug === 'sizes').items,
      materials: taxons.find((t) => t.slug === 'materials').items,
      patterns: taxons.find((t) => t.slug === 'patterns').items,
      styles: taxons.find((t) => t.slug === 'styles').items,
      discounts: taxons.find((t) => t.slug === 'discounts').items,
      sc_sizes: taxons.find((t) => t.slug === 'sc-sizes').items,
    }
  }

  protected async getTaxonChildren(
    parentPermalink,
    nameKeyword = '',
    taxonId = '',
  ) {
    return (
      await getTaxons(this.apiContext, {
        filter: {
          name_cont: nameKeyword,
          parentPermalink,
          taxonId,
          depth_eq: '1',
        },
        per_page: 20,
      })
    ).data
  }

  protected getTaxon(taxonId) {
    return getTaxon(this.apiContext, taxonId)
  }

  protected addTaxon(parentPermalink, name) {
    return addTaxon(this.apiContext, parentPermalink, name)
  }

  protected updateTaxonProductCount() {
    return updateTaxonProductCount(this.apiContext)
  }

  protected getPayouts(params: IQuery = {}) {
    return getPayouts(this.apiContext, {
      ...params,
      filter: { ...params.filter },
    })
  }

  protected getPayout(payoutId) {
    return getPayout(this.apiContext, payoutId)
  }

  protected createPayout(params = {}) {
    return savePayout(this.apiContext, params)
  }

  protected updatePayout(payout_id, params = {}) {
    return savePayout(this.apiContext, params, payout_id)
  }

  protected deletePayout(payout_id) {
    return deletePayout(this.apiContext, payout_id)
  }

  protected refreshPayoutItems(payoutId) {
    return refreshPayoutItems(this.apiContext, payoutId)
  }

  protected addPayoutItem(payoutId, cLineItem) {
    return addPayoutItem(this.apiContext, payoutId, cLineItem)
  }

  protected addPayoutItemsByConsignmentId(
    payoutId,
    consignmentId,
    isIncludeQuantity = false,
  ) {
    return addPayoutItemsByConsignmentId(
      this.apiContext,
      payoutId,
      consignmentId,
      isIncludeQuantity,
    )
  }

  protected removeAllPayoutItems(payoutId) {
    return removeAllPayoutItems(this.apiContext, payoutId)
  }

  protected updatePayoutState(payoutId, state) {
    return updatePayoutState(this.apiContext, payoutId, state)
  }

  protected deletePayoutItem(payoutId, payoutItemId) {
    return deletePayoutItem(this.apiContext, payoutId, payoutItemId)
  }

  protected addProductDiscount(product_id, discount) {
    return addProductDiscount(this.apiContext, {
      ...discount,
      product_id: product_id,
    })
  }

  protected discountProductDirect(product_id, discount_percentage) {
    return discountProductDirect(
      this.apiContext,
      product_id,
      discount_percentage,
    )
  }

  protected deleteProductDiscount(discount_id) {
    return deleteProductDiscount(this.apiContext, discount_id)
  }

  protected deleteProduct(product_id) {
    return deleteProduct(this.apiContext, product_id)
  }

  protected getProductFbDes(skus) {
    return getProductFbDes(this.apiContext, skus)
  }

  protected downloadTextedPhotos(params) {
    return downloadTextedPhotos(this.apiContext, params)
  }

  protected getProductShortDes(skus) {
    return getProductShortDes(this.apiContext, skus)
  }

  protected getStockLocations() {
    return getStockLocations(this.apiContext)
  }

  // protected updateStockItem(id, data) {
  //   return updateStockItem(this.apiContext, id, data)
  // }

  protected updateStockItemArea(id, stock_location_area_id) {
    return updateStockItem(this.apiContext, id, { stock_location_area_id })
  }

  protected updateStockItemCountOnHand(id, count) {
    return updateStockItem(this.apiContext, id, { count_on_hand: count })
  }

  protected getStoreCredits(id, params = {}) {
    return getStoreCredits(this.apiContext, id, params)
  }

  protected getImages(params: IQuery = {}) {
    return getImages(this.apiContext, {
      ...params,
      filter: { ...params.filter },
    })
  }

  protected getStockLocationAreas(
    stock_location_id_eq = 1,
    params: IQuery = {},
  ) {
    return getStockLocationAreas(this.apiContext, {
      ...params,
      filter: { ...params.filter, stock_location_id_eq },
    })
  }

  protected clearStockLocationArea(stock_location_area_id) {
    return clearStockLocationArea(this.apiContext, stock_location_area_id)
  }
}

export default ScClient
