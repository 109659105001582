import Http from '../Http'
import { IQuery } from '../interfaces/Query'
import { IToken } from '../interfaces/Token'
import { Routes } from '../routes'
import { IPayoutResult } from '@/api_client/interfaces/Payout'

export default class LogEntry extends Http {
  public async show(token: IToken, params: IQuery = {}): Promise<any> {
    return (await this.spreeResponse(
      'get',
      Routes.logEntryPath(),
      token,
      params,
    )) as any
  }

  public async create(token?: IToken, data: any = {}): Promise<any> {
    return (await this.spreeResponse(
      'post',
      Routes.logEntryPath(),
      token,
      null,
      data,
    )) as any
  }
}
