import { ApiContext } from '../../types'
import getCurrentBearerToken from '@/api_client/api/authentication/getCurrentBearerToken'
import { RelationType } from '@spree/storefront-api-v2-sdk/types/interfaces/Relationships'
import { addHostToProductImages } from '@/api_client/api/serializers/image'
import { deserializeProduct } from '@/api_client/api/serializers/product'

export default async function connectProductConsignmentLineItem(
  { client, config }: ApiContext,
  productId,
): Promise<any> {
  try {
    const token = await getCurrentBearerToken({
      client,
      config,
    })

    const result = await client.products.connect_consignment_line_item(
      { bearerToken: token },
      productId,
    )

    if (result.isSuccess()) {
      // const data = result.success()
      // const productsData = addHostToProductImages(data, config)
      // const r = deserializeProduct(productsData)
      return true
    } else {
      throw result.fail()
    }
  } catch (e) {
    console.error(e)
    throw e
  }
}
