import { Address, ApiContext } from '../../types'
import getCurrentBearerToken from '@/api_client/api/authentication/getCurrentBearerToken'

export default async function updateAddress(
  { client, config }: ApiContext,
  addressId,
  params: Address,
): Promise<void> {
  const bearerToken = await getCurrentBearerToken({
    client,
    config,
  })

  // const serializedAddress = serializeAddress(params)
  const result = await client.account.updateAddress(
    { bearerToken },
    addressId,
    { address: params },
  )

  if (result.isFail()) {
    console.log('result.fail()')
    console.log(result.fail())
    throw result.fail()
  }
}
