import { ApiContext } from '../../types'
import getCurrentBearerOrCartToken from '../authentication/getCurrentBearerOrCartToken'
import { deserializePromotions } from '@/api_client/api/serializers/promotion'
import moment from 'moment'

export default async function getPromotions({ client, config }: ApiContext) {
  const token = await getCurrentBearerOrCartToken({ client, config })
  // console.log(new Date().toDateString())
  // console.log(new Date().toISOString())
  // console.log(new Date().toLocaleTimeString())
  // console.log(new Date().getFullYear())
  // console.log(new Date().getMonth())
  // console.log(new Date().getDate())
  // console.log(new Date().getDay())
  const today = new Date()
  const dateString =
    today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()
  const result = await client.checkout.promotions(token, {
    include: '',
    filter: {
      expires_at_from_or_null: dateString,
      // promotion_category_id_eq: 2,
      code_present: 1,
    }, // admin only
  })

  if (result.isSuccess()) {
    const payload = result.success()
    const included = payload.included
    // console.log(payload)
    const r = payload.data.map((d) => deserializePromotions(d, included))
    // console.log(r)
    return r
  } else {
    throw result.fail()
  }
}
