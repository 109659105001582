import { ApiContext } from '../../types'
import getCurrentBearerToken from '../authentication/getCurrentBearerToken'
import { IQuery } from '@/api_client/interfaces/Query'
import { deserializeUser } from '@/api_client/api/serializers/user'
import { deserializeAddress } from '@/api_client/api/serializers/address'
import { deserializeStoreCredit } from '@/api_client/api/serializers/store_credit'

export default async function getAddresses(
  { client, config }: ApiContext,
  params: IQuery = {},
) {
  const bearerToken = await getCurrentBearerToken({ client, config })

  if (params.filter) {
    params.filter.id_eq = params.filter.id
  }

  // params.include = 'ship_address,payout_methods,spree_roles'
  const response = await client.account.getAddresses({ bearerToken }, params)
  if (response.isSuccess()) {
    const success = response.success()
    // console.log(success)
    const data = success.data
    const included = success.included
    // result.data = result.data.map(deserializeUser)
    // return data.map((item) => deserializeAddress(item, included))

    return {
      data: data.map((d) => deserializeAddress(d, included)),
      meta: success.meta,
    }
  } else {
    console.log('getUsers: fail')
    throw response.fail()
  }
}
