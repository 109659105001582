import { ApiContext } from '../../types'
import getCurrentBearerToken from '../authentication/getCurrentBearerToken'

import { IQuery } from '@/api_client/interfaces/Query'
import { deserializeCMSPage } from '@/api_client/api/serializers/cms_page'

export default async function getCmsPages(
  { client, config }: ApiContext,
  params: IQuery = {},
): Promise<any> {
  try {
    const token = await getCurrentBearerToken({
      client,
      config,
    })

    const response = await client.cms_pages.list(
      { bearerToken: token },
      {
        ...params,
        per_page: params.per_page || 10,
        sort: '-id',
      },
    )
    if (response.isSuccess()) {
      const payload = response.success()
      console.log(payload)
      const data = payload.data.map((order) =>
        deserializeCMSPage(order, payload.included || [], config),
      )
      return { ...payload, data }
    } else {
      throw response.fail()
    }
  } catch (e) {
    console.error(e)
    throw e
  }
}
