import { ApiContext } from '../../types'
import { AuthHelper } from '@/api_client'

export default async function getCurrentBearerToken({
  client,
  config,
}: ApiContext): Promise<string> {
  const token = await AuthHelper.getOAuthToken()

  if (!token) {
    return
  }
  // for testing access token expired
  // token.access_token = '1'

  const tokenExpiresAt = token.created_at + token.expires_in
  const currentTime = Date.now() / 1000

  if (currentTime < tokenExpiresAt) {
    return token.access_token
  }

  if (token.refresh_token == null) {
    // throw Error()
    console.log('token.refresh_token == null')
    window.location.href = '/#/login'
    return
  }

  const result = await client.authentication.refreshToken({
    refresh_token: token.refresh_token,
  })
  if (result.isFail()) {
    console.log('getCurrentBearerToken- fail')
    await AuthHelper.removeOAuthToken()
    // window.location.href = '/#/login'
    // await config.auth.removeCartToken()
    console.error(result.fail())
    // throw Error('ads')
    return
  }

  const newToken = result.success()
  await AuthHelper.changeOAuthToken(newToken)

  return newToken.access_token
}
