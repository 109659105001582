import { ApiContext } from '../../types'
import getCurrentBearerOrCartToken from '@/api_client/api/authentication/getCurrentBearerOrCartToken'

export default async function getShiftTable(
  { client, config }: ApiContext,
  shift_date_from,
  shift_date_to,
  user_id = null,
) {
  try {
    const token = await getCurrentBearerOrCartToken({ client, config })
    const result = await client.tools.getShiftTable(token, {
      shift_date_from,
      shift_date_to,
      user_id,
    })
    if (result.isSuccess()) {
      const payload = result.success()
      return payload
    } else {
      // console.log('not success');
      console.log(result.fail())
      return null
    }
  } catch (e) {
    console.error('getShipments:', e)
    // throw e;
    return null
  }
}
