import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import User from '@/views/User.vue'
import { h, resolveComponent } from 'vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: DefaultLayout,
    redirect: '/dashboard',
    meta: { description: 'wtf' },
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),
      },
      {
        path: '/products',
        name: 'Products',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '@/views/products/Products.vue'
          ),
      },
      {
        path: '/products/:id',
        name: 'Product',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '@/views/products/Product.vue'
          ),
      },
      {
        path: '/stock_location_areas',
        name: 'Stock Location Areas',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '@/views/products/StockLocationAreas.vue'
          ),
      },
      {
        path: '/taxons',
        name: 'Taxons',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '@/views/products/Taxons.vue'
          ),
      },
      {
        path: '/taxons/:id',
        name: 'Taxon',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '@/views/products/Taxon.vue'
          ),
      },
      {
        path: '/orders',
        name: 'Orders',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "dashboard" */ '@/views/Orders.vue'),
      },
      {
        path: '/orders/:id',
        name: 'Order',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "dashboard" */ '@/views/Order.vue'),
      },
      {
        path: '/consignments',
        name: 'Consignments',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '@/views/Consignments.vue'
          ),
      },
      {
        path: '/consignments/:id',
        name: 'Consignment',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "dashboard" */ '@/views/Consignment.vue'),
      },
      {
        path: '/payouts',
        name: 'Payouts',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "dashboard" */ '@/views/Payouts.vue'),
      },
      {
        path: '/payouts/:id',
        name: 'Payout',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "dashboard" */ '@/views/Payout.vue'),
      },
      {
        path: '/cms_pages',
        name: 'CMS pages',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '@/views/storefront/CmsPages.vue'
          ),
      },
      {
        path: '/cms_pages/:id',
        name: 'CMS page',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '@/views/storefront/CmsPage.vue'
          ),
      },
      {
        path: '/home_sections',
        name: 'Home sections',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '@/views/storefront/HomeSections.vue'
          ),
      },
      {
        path: '/hr/shift-management',
        name: 'Shift Management',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '@/views/hr/ShiftManagement.vue'
          ),
      },
      {
        path: '/hr/shift-table',
        name: 'Shift Table',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '@/views/hr/ShiftTable.vue'
          ),
      },
      {
        path: '/report/sales',
        name: 'Sales Report',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '@/views/reports/SalesReport.vue'
          ),
      },
      {
        path: '/report/performances',
        name: 'Performances Report',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '@/views/reports/PerformancesReport.vue'
          ),
      },
      {
        path: '/report/consignments',
        name: 'Consignments Report',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '@/views/reports/ConsignmentsReport.vue'
          ),
      },
      // {
      //   path: '/report/payouts',
      //   name: 'Payouts Report',
      //   // route level code-splitting
      //   // this generates a separate chunk (about.[hash].js) for this route
      //   // which is lazy-loaded when the route is visited.
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "dashboard" */ '@/views/reports/PayoutsReport.vue'
      //     ),
      // },
      // {
      //   path: '/report/products',
      //   name: 'Products Report',
      //   // route level code-splitting
      //   // this generates a separate chunk (about.[hash].js) for this route
      //   // which is lazy-loaded when the route is visited.
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "dashboard" */ '@/views/reports/ProductsReport.vue'
      //     ),
      // },
      {
        path: '/tools/unsold-items',
        name: 'unsold-items',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '@/views/tools/UnsoldItemsPage.vue'
          ),
      },
      {
        path: '/tools/product-fb-des',
        name: 'product-fb-des',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '@/views/tools/ProductFbDes.vue'
          ),
      },
      {
        path: '/tools/product-photo-batch',
        name: 'product-photo-batch',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '@/views/tools/ProductPhotoBatch.vue'
          ),
      },
      {
        path: '/tools/label-batch',
        name: 'label-batch',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '@/views/tools/LabelBatch.vue'
          ),
      },
      {
        path: '/tools/product-photo-upload',
        name: 'product-photo-upload',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '@/views/tools/ProductPhotoUpload.vue'
          ),
      },
      {
        path: '/tools/product-measurement',
        name: 'product-measurement',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '@/views/tools/ProductMeasurementPage.vue'
          ),
      },
      {
        path: '/tools/product-short-des',
        name: 'product-short-des',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '@/views/tools/ProductShortDes.vue'
          ),
      },
      {
        path: '/tools/product-location',
        name: 'product-location',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '@/views/tools/ProductLocation.vue'
          ),
      },
      {
        path: '/images',
        name: 'Images',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "dashboard" */ '@/views/Images.vue'),
      },
      {
        path: '/customers',
        name: 'Customers',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "dashboard" */ '@/views/Users.vue'),
      },
      {
        path: '/employees',
        name: 'Employees',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "Employees" */ '@/views/Employees.vue'),
      },
      {
        path: '/customers/:id',
        name: 'Customer',
        component: User,
        children: [
          {
            path: 'info',
            alias: '/customers/:id',
            name: 'Customer Info',
            component: () =>
              import(
                /* webpackChunkName: "dashboard" */ '@/components/user/UserDetailInfo.vue'
              ),
          },
          {
            path: 'orders',
            alias: '/customers/:id/orders',
            name: 'Customer Orders',
            component: () =>
              import(
                /* webpackChunkName: "dashboard" */ '@/components/user/UserDetailOrders.vue'
              ),
          },
          {
            path: 'consignments',
            name: 'Customer Consignments',
            component: () =>
              import(
                /* webpackChunkName: "dashboard" */ '@/components/user/UserDetailConsignments.vue'
              ),
          },
          {
            path: 'credits',
            name: 'Customer Credits',
            component: () =>
              import(
                /* webpackChunkName: "dashboard" */ '@/components/user/UserDetailCredits.vue'
              ),
          },
          {
            path: 'addresses',
            name: 'Customer Addresses',
            component: () =>
              import(
                /* webpackChunkName: "dashboard" */ '@/components/user/UserDetailAddresses.vue'
              ),
          },
          {
            path: 'payouts',
            name: 'Customer Payouts',
            component: () =>
              import(
                /* webpackChunkName: "dashboard" */ '@/components/user/UserDetailPayouts.vue'
              ),
          },
          {
            path: 'reset-password',
            name: 'Customer Reset Passwrod',
            component: () =>
              import(
                /* webpackChunkName: "dashboard" */ '@/components/user/UserDetailResetPassword.vue'
              ),
          },
          {
            path: 'update-email',
            name: 'Customer email',
            component: () =>
              import(
                /* webpackChunkName: "dashboard" */ '@/components/user/UserDetailUpdateEmail.vue'
              ),
          },
          {
            path: 'Roles',
            name: 'User Roles',
            component: () =>
              import(
                /* webpackChunkName: "dashboard" */ '@/components/user/UserDetailRoles.vue'
              ),
          },
          {
            path: 'Employment',
            name: 'User Employment',
            component: () =>
              import(
                /* webpackChunkName: "dashboard" */ '@/components/user/UserDetailEmployment.vue'
              ),
          },
          {
            path: 'payout-methods',
            name: 'Customer Payout Methods',
            component: () =>
              import(
                /* webpackChunkName: "dashboard" */ '@/components/user/UserDetailPayoutMethods.vue'
              ),
          },
        ],
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login.vue'),
  },
  {
    path: '/pos',
    name: 'POS',
    component: () => import('@/views/POS.vue'),
  },
  {
    path: '/restricted',
    name: 'Restricted',
    component: () => import('@/views/Restricted.vue'),
  },
  {
    path: '/chats',
    name: 'Chats',
    component: () => import('@/views/Chats.vue'),
  },
  {
    path: '/pages',
    redirect: '/pages/404',
    name: 'Pages',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      },
    },
    children: [
      {
        path: '404',
        name: 'Page404',
        component: () => import('@/views/pages/Page404.vue'),
      },
      {
        path: '500',
        name: 'Page500',
        component: () => import('@/views/pages/Page500.vue'),
      },
      // {
      //   path: 'login',
      //   name: 'Login',
      //   component: () => import('@/views/pages/Login.vue'),
      // },
      {
        path: 'register',
        name: 'Register',
        component: () => import('@/views/pages/Register.vue'),
      },
    ],
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
})

export default router
