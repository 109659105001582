import { ApiContext } from '../../types'
import getCurrentBearerOrCartToken from '@/api_client/api/authentication/getCurrentBearerOrCartToken'
import { deserializeAttendance } from '@/api_client/api/serializers/attendance'

export default async function getAttendances(
  { client, config }: ApiContext,
  params,
) {
  try {
    const token = await getCurrentBearerOrCartToken({ client, config })
    const result = await client.attendances.show(token, {
      ...params,
      include: 'user',
      sort: params.sort ?? 'shift_date,user_id',
      per_page: params.per_page ?? 500,
    })
    if (result.isSuccess()) {
      const payload = result.success()

      // console.log(payload)
      // cons
      const data = payload.data.map((e) =>
        deserializeAttendance(e, payload.included),
      )

      return {
        data: data,
        meta: payload.meta,
      }
    } else {
      // console.log('not success');
      console.log(result.fail())
      return null
    }
  } catch (e) {
    console.error('getShipments:', e)
    // throw e;
    return null
  }
}
