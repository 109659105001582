import Http from '../Http'
import { IQuery } from '../interfaces/Query'
import { IToken } from '../interfaces/Token'
import { Routes } from '../routes'
import {
  ICmsPageResult,
  ICmsPagesResult,
} from '@/api_client/interfaces/CmsPage'

export default class CmsPages extends Http {
  public async create(
    token?: IToken,
    params: IQuery = {},
  ): Promise<ICmsPageResult> {
    return (await this.spreeResponse(
      'post',
      Routes.cmsPagesPath(),
      token,
      params,
    )) as ICmsPageResult
  }

  public async update(
    token: IToken,
    id: number,
    data: any,
    params = {},
  ): Promise<ICmsPageResult> {
    return (await this.spreeResponse(
      'patch',
      Routes.cmsPagePath(id),
      token,
      params,
      data,
    )) as ICmsPageResult
  }

  public async list(
    token: IToken,
    params: IQuery = {},
  ): Promise<ICmsPagesResult> {
    return (await this.spreeResponse(
      'get',
      Routes.cmsPagesPath(),
      token,
      params,
    )) as ICmsPagesResult
  }

  public async show(
    token: IToken,
    id,
    params: IQuery = {},
  ): Promise<ICmsPageResult> {
    return (await this.spreeResponse(
      'get',
      Routes.cmsPagePath(id),
      token,
      params,
    )) as ICmsPageResult
  }
}
