import { ApiContext } from '../../types'
import getCurrentBearerToken from '@/api_client/api/authentication/getCurrentBearerToken'

export default async function applyCouponCode(
  { client, config }: ApiContext,
  orderId,
  coupon_code,
): Promise<boolean> {
  try {
    const token = await getCurrentBearerToken({
      client,
      config,
    })

    const result = await client.cart.applyCouponCode(
      { bearerToken: token },
      orderId,
      { coupon_code },
    )

    if (result.isSuccess()) {
      // const payload = result.success()
      // console.log(payload)
      // const cart = deserializeCart(payload.data, payload.included, config)
      //
      // // save loadedId
      // const savedIds = localStorage.getItem('local_order_ids')?.split(',') || []
      // if (savedIds[0] === '') {
      //   savedIds.splice(0, 1)
      // }
      // savedIds.push(cart.id.toString())
      // localStorage.setItem('local_order_ids', savedIds.join(','))

      // return cart
      return true
    } else {
      console.error(result.fail())
      throw result.fail()
    }
  } catch (e) {
    console.error(e)
    throw e
  }
}
