import Http from '@/chat_client/Http'
import { JsonApiListResponse } from '@/chat_client/interfaces/JsonApi'
import { Message } from '@/chat_client/serializers/message'

export default class Messages extends Http {
  public async list(
    token: string,
    params = {},
  ): Promise<JsonApiListResponse<Message>> {
    return (await this.chatResponse('get', 'messages/', token, params)) as any
  }

  public async create(token: string, data = {}): Promise<any> {
    return (await this.chatResponse(
      'post',
      'messages/',
      token,
      {},
      data,
    )) as any
  }

  // public async delete(token: string, id): Promise<any> {
  //   return (await this.chatResponse(
  //     'delete',
  //     '/api/v1/messages',
  //     token,
  //     {},
  //   )) as any
  // }
}
