import { ApiContext, Cart } from '../../types'
import { cartParams } from '../common/cart'
import { deserializeCart } from '../serializers/cart'
import getCurrentBearerToken from '@/api_client/api/authentication/getCurrentBearerToken'
import { addHostToIncluded } from '@/api_client/api/serializers/image'

export default async function getOrder(
  { client, config }: ApiContext,
  orderId,
  { isUseNumber = false, isUseVariantId = false, isIncludeInventory = false },
): // isUseNumber = false,
// isUseVariantId = false,
Promise<Cart> {
  try {
    const token = await getCurrentBearerToken({
      client,
      config,
    })
    // console.log('getOrder2')
    let include =
      cartParams.include +
      ',created_by,payments,payments.payment_method,store_credits,approver,adjustments,images'
    if (isIncludeInventory) {
      include += ',line_items.inventory_units'
    }
    const params = {
      include: include,
      use_number: '',
      use_variant_id: '',
      filter: {},
    }
    if (isUseNumber) {
      params.use_number = 'true'
    }
    if (isUseVariantId) {
      params.use_variant_id = 'true'
      params.filter = { completed_at_not_null: '1' }
      // params.use_product_id = 'true'
      params.include = ''
    }
    // console.log(params)
    const result = await client.cart.show(
      { bearerToken: token },
      orderId,
      params,
    )
    // console.log('getOrder1')
    if (result.isSuccess()) {
      const payload = result.success()
      console.log(payload)
      payload.included = addHostToIncluded(payload.included, config)
      const cart = deserializeCart(payload.data, payload.included, config)
      return cart
    } else {
      console.log(result.fail())
      throw result.fail()
    }
  } catch (e) {
    console.error(e)
    throw e
  }
}
