import Http from '../Http'
import { IQuery } from '../interfaces/Query'
import { IToken } from '../interfaces/Token'
import { Routes } from '../routes'

export default class Tools extends Http {
  public async genProductFbDes(
    token: IToken,
    params: IQuery = {},
  ): Promise<any> {
    return (await this.spreeResponse(
      'get',
      Routes.toolProductFbDesPath(),
      token,
      params,
    )) as any
  }

  public async genProductShortDes(
    token: IToken,
    params: IQuery = {},
  ): Promise<any> {
    return (await this.spreeResponse(
      'get',
      Routes.toolProductShortDesPath(),
      token,
      params,
    )) as any
  }

  public async downloadTextedPhotos(
    token: IToken,
    params: IQuery = {},
  ): Promise<any> {
    return (await this.spreeResponse(
      'get',
      Routes.downloadTextedPhotosPath(),
      token,
      params,
      null,
      true,
      'blob',
    )) as any
  }

  public async clearStockLocationArea(
    token: IToken,
    params: IQuery = {},
  ): Promise<any> {
    return (await this.spreeResponse(
      'get',
      Routes.clearStockLocationAreaPath(),
      token,
      params,
    )) as any
  }

  public async reCountStockLocationArea(
    token: IToken,
    params: IQuery = {},
  ): Promise<any> {
    return (await this.spreeResponse(
      'get',
      Routes.reCountStockLocationAreaPath(),
      token,
      params,
    )) as any
  }

  public async getShiftTable(token: IToken, params: IQuery = {}): Promise<any> {
    return (await this.spreeResponse(
      'get',
      Routes.shiftTablePath(),
      token,
      params,
    )) as any
  }

  public async updateTaxonProductCount(
    token: IToken,
    params: IQuery = {},
  ): Promise<any> {
    return (await this.spreeResponse(
      'get',
      Routes.updateTaxonProductCountPath(),
      token,
      params,
    )) as any
  }

  public async logMultiProducts(token: IToken, data): Promise<any> {
    return (await this.spreeResponse(
      'post',
      Routes.logMultiProductsPath(),
      token,
      null,
      data,
    )) as any
  }
}
