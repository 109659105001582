import { ApiContext } from '../../types'
import getCurrentBearerToken from '@/api_client/api/authentication/getCurrentBearerToken'

export default async function deleteProduct(
  { client, config }: ApiContext,
  product_id,
): Promise<any> {
  try {
    const token = await getCurrentBearerToken({ client, config })
    const result = await client.products.delete(
      { bearerToken: token },
      product_id,
    )

    if (result.isSuccess()) {
      const payload = result.success()
      // console.log(payload)
      // const t = deserializeTaxons([payload.data], [])
      return true
    } else {
      return false
    }
  } catch (e) {
    console.error(e)
    throw e
  }
}
