import Http from '../Http'
import { IToken } from '../interfaces/Token'
import { Routes } from '../routes'

export default class Shipments extends Http {
  public async updateShipmentTrackNumber(
    token: IToken,
    id,
    tracking,
  ): Promise<any> {
    return await this.spreeResponse(
      'patch',
      Routes.shipmentPath(id),
      token,
      {},
      { shipment: { tracking: tracking } },
    )
  }

  public async ship(
    token: IToken,
    id,
    isPlaceDeliveryOrder = false,
  ): Promise<any> {
    return await this.spreeResponse(
      'patch',
      Routes.shipmentShipPath(id),
      token,
      {},
      { shipment: { place_delivery: isPlaceDeliveryOrder ? '1' : null } },
    )
  }

  public async place(token: IToken, id): Promise<any> {
    return await this.spreeResponse(
      'patch',
      Routes.shipmentPlacePath(id),
      token,
    )
  }
}
