import { ApiContext, LineItem } from '../../types'
import { deserializeLineItem } from '../serializers/cart'
import getCurrentBearerToken from '@/api_client/api/authentication/getCurrentBearerToken'

// old
export default async function addLineItem(
  { client, config }: ApiContext,
  orderId,
  variantId,
  sku = '',
): Promise<LineItem> {
  try {
    const token = await getCurrentBearerToken({
      client,
      config,
    })

    const result = await client.cart.addItem1(
      { bearerToken: token },
      orderId,
      { include: 'variant,variant.images,order' },
      {
        line_item: {
          variant_id: variantId,
          order_id: orderId,
          sku: sku,
          quantity: 1,
        },
      },
    )

    if (result.isSuccess()) {
      const payload = result.success()
      // console.log(payload)
      const lineItem = deserializeLineItem(
        payload.data,
        payload.included,
        config,
      )
      return lineItem
    } else {
      throw result.fail()
    }
  } catch (e) {
    console.error(e)
    throw e
  }
}
