import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import { CoreuiVue } from '@coreui/vue-pro'
import { CIcon } from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'
import ScClient from '@/api_client/ScClient'
import vSelect from 'vue-select'
import { initializeApp } from 'firebase/app'
import filters from '@/helpers/filters'
import money from 'v-money3'
import { createI18n } from 'vue-i18n'
import { messages } from '@/i18n/messags'
import CoreuiVue from '@coreui/vue-pro'
import { AuthHelper } from '@/api_client'
import axios from 'axios'
import 'vue-select/dist/vue-select.css'
import ApiChatClient from '@/chat_client/ApiChatClient'

const firebaseConfig = {
  apiKey: 'AIzaSyDucqGRd-On829RIne4XUAzcn8LLYqY07Y',
  authDomain: 'second-corner.firebaseapp.com',
  projectId: 'second-corner',
  storageBucket: 'second-corner.appspot.com',
  messagingSenderId: '989917059759',
  appId: '1:989917059759:web:b5d46822efe3d2169cd25a',
  measurementId: 'G-13QRQ6LY46',
}

const lang = localStorage.getItem('lang') || 'vi'
const i18n = createI18n({
  // something vue-i18n options here ...
  locale: 'vi', // set locale
  // fallbackLocale: 'en', // set fallback locale
  messages,
  silentTranslationWarn: lang == 'en',
  silentFallbackWarn: true,
})

// const currentRole = AuthHelper.isRoleIn('sadmin')

// function isRoleIn(string) {
//   return AuthHelper.isRoleIn(string)
// }

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

function isMobile() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent,
  )
}

const app = createApp(App)
// console.log(window.location.origin)
app.config.globalProperties.apiClient = new ScClient(
  window.location.origin.includes('https'),
)

app.config.globalProperties.apiChatClient = new ApiChatClient(
  window.location.origin.includes('https'),
)
app.config.globalProperties.filters = filters
app.config.globalProperties.firebase = initializeApp(firebaseConfig)
app.config.globalProperties.capitalize = capitalizeFirstLetter
app.config.globalProperties.isMobile = isMobile
app.config.globalProperties.http = axios

app.use(store)
app.use(router)
app.use(CoreuiVue)
app.use(money)
app.use(i18n)

// restrict access by IP
app.use((to, from, next) => {
  // restrict access by IP
  // console.log('fetch IP')
  try {
    const check_time = localStorage.getItem('nextIPCheckTime')
    console.log(check_time)
    if (check_time && parseInt(check_time) > new Date().getTime()) {
      console.log('no need check IP')
      return
    }
    console.log('check IP')
    fetch('https://api.ipify.org?format=json')
      .then((x) => x.json())
      .then(({ ip }) => {
        // console.log(ip)
        const isAllowedIPAccess =
          AuthHelper.allowedIPs.includes(ip) ||
          AuthHelper.allowedUserIds.includes(AuthHelper.getUserId())
        if (!isAllowedIPAccess) {
          // logout
          AuthHelper.removeOAuthToken()
        } else {
          // after one hour
          localStorage.setItem(
            'nextIPCheckTime',
            (new Date().getTime() + 60 * 60 * 1000).toString(),
          )
        }
      })
  } catch (e) {
    console.log(e)
  }
})

// Vue.use(VueMeta)
app.provide('icons', icons)
app.component('CIcon', CIcon)
app.component('v-select', vSelect)

app.mount('#app')
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
i18n.global.locale = lang
