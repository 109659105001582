import { ApiContext } from '../../types'
import getCurrentBearerToken from '@/api_client/api/authentication/getCurrentBearerToken'

// old
export default async function deletePayoutMethod(
  { client, config }: ApiContext,
  user_id,
  payout_method_id,
): Promise<any> {
  try {
    const token = await getCurrentBearerToken({
      client,
      config,
    })

    // let result

    // if (payout_method_id == null) {
    const result = await client.users.deletePayoutMethods(
      { bearerToken: token },
      user_id,
      payout_method_id,
    )
    console.log(result)
    // } else {
    //   result = await client.users.updatePayoutMethods(
    //     { bearerToken: token },
    //     user_id,
    //     payout_method_id,
    //     payout_method,
    //   )
    // }

    // if (result.isSuccess()) {
    //   const payload = result.success()
    //   return deserializePayoutMethod(payload.data)
    // } else {
    //   throw result.fail()
    // }
    return true
  } catch (e) {
    console.error(e)
    throw e
  }
}
