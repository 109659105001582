import { ApiContext } from '../../types'
import getCurrentBearerToken from '@/api_client/api/authentication/getCurrentBearerToken'

export default async function setShipmentShip(
  { client, config }: ApiContext,
  id,
  isPlaceDelivery = false,
) {
  try {
    const token = await getCurrentBearerToken({
      client,
      config,
    })

    const response = await client.shipments.ship(
      { bearerToken: token },
      id,
      isPlaceDelivery,
    )
    if (response.isSuccess()) {
      // console.log(response.success())
      return true
    } else {
      throw response.fail()
    }
  } catch (e) {
    console.error(e)
    throw e
  }
}
