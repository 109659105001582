import { CouponCode as CouponCodeAttr } from '@spree/storefront-api-v2-sdk/types/interfaces/endpoints/CartClass'
import { Consignment, ConsignmentLineItem, CouponCode } from '../../types'
import { RelationType } from '@spree/storefront-api-v2-sdk/types/interfaces/Relationships'
import { ConsignmentAttr } from '@/api_client/interfaces/Consignment'
import {
  getDateString,
  getDatetimeString,
} from '@/api_client/api/serializers/getters'
import { deserializeUser } from '@/api_client/api/serializers/user'
import { deserializeImage } from '@/api_client/api/serializers/image'

const findAttachment = (attachments: any[], id: string, type: string) => {
  // console.log(attachments)
  // console.log(id)
  // console.log(type)
  if (id == null) {
    return null
  }
  return attachments?.find(
    (e) => e.id.toString() === id.toString() && e.type === type,
  )
}

const findAttachments = (lineItemId, attachments: any[], type: string) => {
  return attachments?.filter(
    (e) =>
      lineItemId.toString() === e.consignment_line_item_id.toString() &&
      e.type === type,
  )
}

export const deserializeConsignmentLineItem = (
  lineItem: any,
  attachments: any[],
  config: any,
  index,
  consignment_number = null,
): ConsignmentLineItem => {
  let variant = null
  let inStock = true
  let image = null

  if (lineItem.relationships.variant.data !== null) {
    variant = findAttachment(
      attachments,
      lineItem.relationships.variant.data.id,
      'variant',
    )
    // console.log('variant')
    // console.log(variant)
    // console.log(lineItem.attributes.variant_id)
    // console.log(attachments)
    if (variant != null) {
      variant.status = variant?.attributes?.status
      variant.name = variant?.attributes?.name
      variant.in_stock = variant?.attributes?.in_stock
      variant.product_id = variant?.attributes?.product_id
      variant.available_on = getDateString(variant?.attributes?.available_on)
      variant.discontinue_on = getDateString(
        variant?.attributes?.discontinue_on,
      )
      if (variant.relationships.images.data.length > 0) {
        inStock = variant?.attributes?.in_stock
        const imageAttachment = findAttachment(
          attachments,
          variant.relationships.images.data[0].id,
          'image',
        )
        if (imageAttachment) {
          image = {
            id: parseInt(imageAttachment.id, 10),
            styles: imageAttachment.attributes.styles.map((style) => ({
              url: style.url,
              width: parseInt(style.width, 10),
              height: parseInt(style.height, 10),
            })),
          }
        }
      }
    }
  }

  const discounts = deserializeDiscounts(
    lineItem.relationships.discounts.data.map((d) =>
      findAttachment(attachments, d.id, d.type),
    ),
  )

  // console.log(discounts)
  const result = {
    _id: parseInt(lineItem.id, 10),
    id: parseInt(lineItem.id, 10),
    variant_id: parseInt(lineItem.relationships.variant.data?.id, 10) || null,
    index: index + 1,
    _description: '',
    _categoriesRef: [],
    name: lineItem.attributes.name,
    in_stock: inStock,
    sku: variant?.attributes?.sku,
    image: image,
    price: {
      original: variant?.attributes?.compare_at_price,
      current: variant?.attributes?.price,
    },
    displayPrice: variant?.attributes?.display_price,
    displayTotal: variant?.attributes?.display_total,
    quantity: lineItem.attributes.quantity,
    options: {},
    user_suggested_sell_price:
      lineItem.attributes.user_suggested_sell_price || '',
    confirmed_sell_price:
      lineItem.attributes.confirmed_sell_price == null
        ? null
        : Math.round(lineItem.attributes?.confirmed_sell_price),
    commission_percentage: lineItem.attributes.commission_percentage || '',
    user_purchased_date: lineItem.attributes.user_purchased_date || '',
    user_purchased_price: lineItem.attributes.user_purchased_price || '',
    user_purchased_from: lineItem.attributes.user_purchased_from || '',
    initial_estimates_sell_price:
      lineItem.attributes.initial_estimates_sell_price || '',
    custom_commission_percentage:
      lineItem.attributes.custom_commission_percentage || '',
    final_sell_price: lineItem.attributes.final_sell_price || '',
    customer_revenue: lineItem.attributes.customer_revenue || '',
    commission_fee: lineItem.attributes.commission_fee || '',
    is_auto_discount: lineItem.attributes.is_auto_discount,
    category_id: lineItem.attributes.category_id,
    remark: lineItem.attributes.remark,
    cost_price: lineItem.attributes.cost_price,
    brand_id: lineItem.attributes.brand_id,
    size_id: lineItem.attributes.size_id,
    color_id: lineItem.attributes.color_id,
    material_id: lineItem.attributes.material_id,
    brand_name: '',
    condition_id: lineItem.attributes.condition_id,
    payout: null,
    payout_number: lineItem.attributes.payout_number,
    item_status: lineItem.attributes.item_status,
    discounts: discounts,
    product: variant,
    consignment_number:
      consignment_number || 'CS' + (lineItem.attributes.consignment_id + 12000),
    payout_status: '',
  }
  result.payout_status = getPayoutState(result)
  return result
}

const getPayoutState = (lineItem) => {
  if (lineItem.payout_number) {
    return 'Paid'
  } else if (
    lineItem.item_status == 'returned' ||
    lineItem.item_status == 'rejected'
  ) {
    return 'Returned'
  } else if (lineItem.item_status == 'donated') {
    return 'Donated'
  } else if (
    !lineItem.in_stock ||
    (lineItem.final_sell_price && lineItem.final_sell_price > 0)
  ) {
    return 'Pending payment'
  } else {
    return 'Unsold'
  }
}

export const deserializeDiscounts = (data: any[]): any[] => {
  if (data == null || data.length == 0 || data[0] == null) {
    return [false, false, false, false, false]
  }
  const discounts = data.map((d) => ({
    id: d.id,
    consignment_line_item_id: d.attributes.consignment_line_item_id,
    discount_percentage: d.attributes.discount_percentage,
    discount_from_day: d.attributes.discount_from_day,
    discount_at: d.attributes.discount_at,
    created_at: d.attributes.created_at,
    updated_at: d.attributes.updated_at,
  }))

  return [0, 1, 2, 3, 4].map(
    (i) => discounts.find((d) => d.discount_percentage == (i + 1) * 10) != null,
  )
}
const filterIncludedConsignmentLineItems = (
  lineItemData: RelationType | RelationType[],
  included: any[],
) => {
  return included?.filter(
    (e) =>
      e.type === 'consignment_line_item' &&
      (lineItemData as RelationType[]).find((d) => d.id === e.id) !== undefined,
  )
}

export const deserializeConsignment = (
  apiCart: ConsignmentAttr,
  included: any[],
  config: any,
): Consignment => {
  // console.log('deserializeConsignment')
  const theUser = deserializeUser(
    findAttachment(included, apiCart.attributes.user_id?.toString(), 'user'),
    included,
  )

  const image = deserializeImage(
    findAttachment(
      included,
      (apiCart.relationships.image?.data as RelationType)?.id?.toString(),
      'image',
    ),
  )

  const images = (apiCart.relationships.images?.data as RelationType[]).map(
    (rt) =>
      deserializeImage(findAttachment(included, rt?.id?.toString(), 'image')),
  )

  return {
    _id: parseInt(apiCart.id, 10),
    id: +apiCart.id,
    email: apiCart.attributes.email,
    number: apiCart.attributes.number,
    state: apiCart.attributes.state,
    payout_state: apiCart.attributes.payout_state,
    total: apiCart.attributes.display_total,
    totalAmount: parseFloat(apiCart.attributes.total),
    itemTotalAmount: parseFloat(apiCart.attributes.item_total),
    itemTotal: apiCart.attributes.display_item_total,
    shipTotal: apiCart.attributes.display_ship_total,
    shipTotalAmount: parseFloat(apiCart.attributes.ship_total),
    taxTotalAmount: parseFloat(apiCart.attributes.tax_total),
    adjustmentTotal: parseFloat(apiCart.attributes.adjustment_total),
    lineItems: filterIncludedConsignmentLineItems(
      apiCart.relationships?.consignment_line_items?.data,
      included,
    )?.map((item, index) =>
      deserializeConsignmentLineItem(
        item,
        included,
        config,
        index,
        apiCart.attributes.number,
      ),
    ),
    itemCount: apiCart.attributes?.item_count,
    totalRevenue: apiCart.attributes.total_revenue,
    created_at_o: apiCart.attributes.created_at,
    created_at: getDatetimeString(apiCart.attributes.created_at),
    launch_at_o: apiCart.attributes.launch_at,
    launch_at: getDatetimeString(apiCart.attributes.launch_at),
    expire_at_o: apiCart.attributes.expire_at,
    confirm_price_at: getDatetimeString(apiCart.attributes.confirm_price_at),
    confirm_price_at_o: apiCart.attributes.confirm_price_at,
    expire_at: getDatetimeString(apiCart.attributes.expire_at),
    received_at_o: apiCart.attributes.received_at,
    received_at: getDatetimeString(apiCart.attributes.received_at),
    return_due_o: apiCart.attributes.return_due,
    return_due: getDatetimeString(apiCart.attributes.return_due),
    completedAt: apiCart.attributes.completed_at,
    completed_at: getDatetimeString(apiCart.attributes.completed_at),
    updatedAt: apiCart.attributes.updated_at,
    updated_at: getDatetimeString(apiCart.attributes.updated_at),
    payment_total: apiCart.attributes.payment_total,
    approver_id: apiCart.attributes.approver_id,
    user_id: apiCart.attributes.user_id,
    remark: apiCart.attributes.remark,
    total_cost_price: apiCart.attributes.total_cost_price ?? 0,
    total_item_sold_count: apiCart.attributes.total_item_sold_count ?? 0,
    total_item_unsold_count:
      (apiCart.attributes?.item_count ?? 0) -
      (apiCart.attributes.total_item_sold_count ?? 0),
    total_item_sales: apiCart.attributes.total_item_sales ?? 0,
    unsold_total_cost_price: apiCart.attributes.unsold_total_cost_price,
    single_item_cost_price: apiCart.attributes.single_item_cost_price,
    total_sales: apiCart.attributes.total_sales,
    name: theUser?.name,
    phone: theUser?.phone,
    image: image,
    images: images,
    return_provider: apiCart.attributes.return_provider,
    return_delivery_number: apiCart.attributes.return_delivery_number,
    user: theUser,
    approver: deserializeUser(
      findAttachment(
        included,
        apiCart.attributes.approver_id?.toString(),
        'user',
      ),
      null,
    ),
  }
}

export const serializeCouponCode = ({
  couponCode,
}: CouponCode): CouponCodeAttr => ({
  coupon_code: couponCode,
})
