import { Adjustment, ApiContext, LineItem } from '../../types'
import { deserializeLineItem } from '../serializers/cart'
import getCurrentBearerToken from '@/api_client/api/authentication/getCurrentBearerToken'

// old
export default async function removeAdjustment(
  { client, config }: ApiContext,
  adjustmentId: string,
): Promise<boolean> {
  try {
    const token = await getCurrentBearerToken({
      client,
      config,
    })

    const result = await client.adjustment.delete(
      { bearerToken: token },
      adjustmentId,
    )

    if (result.isSuccess()) {
      return true
    } else {
      throw result.fail()
    }
  } catch (e) {
    console.error(e)
    throw e
  }
}
