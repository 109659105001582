import Http from '@/chat_client/Http'

export default class Rooms extends Http {
  public async list(token: string, params = {}): Promise<any> {
    return (await this.chatResponse('get', 'rooms/', token, params)) as any
  }

  // public async create(token: string, data = {}): Promise<any> {
  //   return (await this.chatResponse(
  //     'post',
  //     '/api/v1/messages',
  //     token,
  //     {},
  //     data,
  //   )) as any
  // }

  // public async delete(token: string, id): Promise<any> {
  //   return (await this.chatResponse(
  //     'delete',
  //     '/api/v1/messages',
  //     token,
  //     {},
  //   )) as any
  // }
}
