import type { ApiContext } from '../../types'
import getCurrentBearerToken from '@/api_client/api/authentication/getCurrentBearerToken'

export default async function clearStockLocationArea(
  { client, config }: ApiContext,
  stock_location_area_id,
) {
  try {
    const token = await getCurrentBearerToken({ client, config })
    const result = await client.tools.clearStockLocationArea(
      { bearerToken: token },
      {
        stock_location_area_id: stock_location_area_id,
      },
    )
    return true
  } catch (e) {
    console.error(e)
    throw e
  }
}
