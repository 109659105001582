import { ApiContext } from '../../types'
import getCurrentBearerToken from '@/api_client/api/authentication/getCurrentBearerToken'

export default async function deleteOrder(
  { client, config }: ApiContext,
  orderId,
): Promise<boolean> {
  try {
    const token = await getCurrentBearerToken({
      client,
      config,
    })

    const result = await client.cart.delete({ bearerToken: token }, orderId)

    if (result.isSuccess()) {
      console.log('deleteOrder-success')
      const savedIds = localStorage.getItem('local_order_ids')?.split(',') || []
      savedIds.forEach((item, index) => {
        if (item === orderId.toString()) savedIds.splice(index, 1)
      })
      localStorage.setItem('local_order_ids', savedIds.join(','))
      console.log('deleteOrder-success1')
      return true
    } else {
      throw result.fail()
    }
  } catch (e) {
    console.error(e)
    throw e
  }
}
