import { ApiContext } from '../../types'
import getCurrentBearerToken from '@/api_client/api/authentication/getCurrentBearerToken'
import { deserializePayoutItem } from '@/api_client/api/serializers/payout'
import { PayoutItem } from '@/api_client/types/payout'
import { deserializeAttendanceLog } from '@/api_client/api/serializers/attendance_record'

// old
export default async function addAttendanceLog(
  { client, config }: ApiContext,
  data,
): Promise<any> {
  try {
    const token = await getCurrentBearerToken({
      client,
      config,
    })

    const result = await client.attendanceRecords.create(
      { bearerToken: token },
      { attendance_record: data },
    )

    if (result.isSuccess()) {
      const payload = result.success()
      return deserializeAttendanceLog(payload.data, payload.included)
    } else {
      throw result.fail()
    }
  } catch (e) {
    console.error(e)
    throw e
  }
}
