import { getDatetimeString } from '@/api_client/api/serializers/getters'

export const deserializeStockLocationArea = (data, include): any => {
  if (data == null) {
    return null
  }

  return {
    id: data.id,
    name: data.attributes.name,
    position: data.attributes.position,
    item_count: data.attributes.item_count,
    stock_location_id: data.attributes.stock_location_id,
    created_at: getDatetimeString(data.attributes.created_at),
    updated_at: getDatetimeString(data.attributes.updated_at),
  }
}
