import { Address, ApiContext } from '../../types'
// import getCurrentBearerToken from '../authentication/getCurrentBearerToken'
import { serializeAddress } from '../serializers/address'
import getCurrentBearerToken from '@/api_client/api/authentication/getCurrentBearerToken'
// import getCurrentBearerToken from "@/api_client/api/authentication/getCurrentBearerToken";

export default async function addLogEntry(
  { client, config }: ApiContext,
  source_type: string,
  source_id: string,
  details: string,
): Promise<void> {
  const bearerToken = await getCurrentBearerToken({
    client,
    config,
  })

  const result = await client.logEntry.create(
    { bearerToken },
    {
      source_type: source_type,
      source_id: source_id,
      details: details,
    },
  )

  if (result.isFail()) {
    console.log('result.fail()')
    console.log(result.fail())
    throw result.fail()
  }
}
